import { Paper } from "@mui/material";
import StyledDashBoardSectionTitleContainer from "../../../components/sections/styled-dashboard-section-title/StyledDashBoardSectionTitleContainer";
import DashboardSection from "../../../components/sections/dashboard-section/DashboardSection";
import TextField from "../../../components/text-fields/TextField";
import DashboardSubSection from "../../../components/sections/dashboard-sub-section/DashboardSubSection";
import DashboardTextWrapper from "../../../components/sections/dashbord-text-wrapper/DashboardTextWrapper";
import BasicInput from "../../../components/inputs/basic-input/BasicInput";
import StyledButton from "../../../components/buttons/StyledButtonComponent";
import defaultImage from "../../../../src/Default-Image.png";
import Box from "@mui/material/Box";
import StyledLink from "../../../components/links/styled-link/StyledLink";
import Carousel from "../../../components/carousel/CarouselComponent";
import { ADMIN_DASHBOARD_RESTAURANT_EDIT } from "../../../config/routes";

import { useTheme } from "@mui/material";

const AdminRestaurantDetailsPage = ({}) => {
	const theme = useTheme();
	const dummyData = [
		{
			displayName: "Chez Croquette",
			city: "Montreal",
			views: "10k",
			likes: "2k",
			bookMarks: "5k",
			id: "1234",
			address: "514 rue du Palais, Montreal, H2V 3B3",
			contact: "Jean Dujardins",
			website: "chez-croq.ca",
			phone: "123 456 789",
			email: "JeanD@chez-croq.ca",
			owners: "Thomas de Montigny, Élizabeth McGovern",
			chefs: "Alfonso Puy, Lionel Basta",
			general_manager: "Jean Dujardins",
			bartender: "Sophie Lacasse",
			sommelier: "Margaret Chesterman",
			hours: [
				"Monday: Closed",
				"Tuesday: Closed",
				"Wednesday: Closed",
				"Thursday: 5:30 – 10:00 PM",
				"Friday: 5:30 – 10:00 PM",
				"Saturday: 5:30 – 10:00 PM",
				"Sunday: Closed",
			],
			open_date: "june 24th 2012",
			facebook: "facebook/chez-croq.com",
			instagram: "@chez-croq",
			payement_type: "Visa, MasterCard, Interac",
			seats: "15",
			accessible: "no",
			reservation: "yes",
			private: "yes",
			wifi: "yes",
			tasting: "no",
			groups: "no",
			deliveries: "yes",
		},
	];

	const generalInfo = [
		{ title: "Name of establishment:", value: dummyData[0].displayName },
		{ title: "Name of contact:", value: dummyData[0].contact },
		{ title: "Website:", value: dummyData[0].email },
		{ title: "Phone Number", value: dummyData[0].phone },
		{ title: "Email address of contact:", value: dummyData[0].email },
	];

	const staffInfo = [
		{ title: "Owner(s):", value: dummyData[0].owners },
		{ title: "Chef(s):", value: dummyData[0].chefs },
		{ title: "General Manager:", value: dummyData[0].general_manager },
		{ title: "Bartender:", value: dummyData[0].bartender },
		{ title: "Sommelier:", value: dummyData[0].sommelier },
	];

	const otherInfo = [
		{ title: "Date of opening:", value: dummyData[0].open_date },
		{ title: "Facebook url:", value: dummyData[0].facebook },
		{ title: "Instagram url:", value: dummyData[0].instagram },
		{ title: "Payment Type:", value: dummyData[0].payement_type },
		{ title: "Seat number:", value: dummyData[0].seats },
		{ title: "Reservations?", value: dummyData[0].reservation },
		{ title: "Private Room?", value: dummyData[0].private },
		{ title: "WI-FI?", value: dummyData[0].wifi },
		{ title: "Tasting Menu?", value: dummyData[0].tasting },
		{ title: "Groups?", value: dummyData[0].groups },
		{ title: "Deliveries?", value: dummyData[0].deliveries },
		{ title: "Accessible?", value: dummyData[0].accessible },
	];

	const atmosphereInfo = [
		{ title: "The address has a terrace?", value: "yes" },
		{ title: "Kid-friendly?", value: "yes" },
		{ title: "Ideal for a date?", value: "yes" },
		{ title: "Friendly service?", value: "yes" },
		{ title: "Professional service?", value: "yes" },
		{ title: "Is the décor desginer?", value: "yes" },
		{ title: "Is the décor sought after?", value: "yes" },
	];

	return (
		<>
			<DashboardSection>
				<Box
					sx={{
						display: "flex",
						flexWrap: "wrap",
						justifyContent: "space-evenly",
						alignItems: "center",
					}}>
					<TextField
						variant={"h1"}
						sx={{
							textAlign: "center",
						}}>
						{dummyData[0].displayName} (viewing)
					</TextField>
					<StyledLink href={"https://tastet.ca/"}>
						<TextField variant={"p"}>View the artice</TextField>
					</StyledLink>
				</Box>
				<Carousel
					images={[
						{ image: defaultImage },
						{ image: defaultImage },
						{ image: defaultImage },
					]}></Carousel>

				<StyledLink href={ADMIN_DASHBOARD_RESTAURANT_EDIT}>
					<TextField variant={"p"}>EDIT PHOTOS</TextField>
				</StyledLink>
			</DashboardSection>
			<DashboardSection>
				{" "}
				<StyledDashBoardSectionTitleContainer>
					<TextField
						variant={"h3"}
						sx={{
							textAlign: { xs: "center", md: "center", lg: "left" },
						}}>
						Quick Info
					</TextField>
				</StyledDashBoardSectionTitleContainer>
				<DashboardTextWrapper>
					<TextField
						variant={"h6"}
						sx={{
							textAlign: { xs: "left", md: "left", lg: "left" },
						}}>
						STATUS
					</TextField>
					<TextField
						variant={"h6"}
						sx={{
							color: "green",
							textAlign: { xs: "left", md: "left", lg: "left" },
						}}>
						OPERATIONAL
					</TextField>
				</DashboardTextWrapper>
				<DashboardTextWrapper>
					<TextField
						variant={"h6"}
						sx={{
							textAlign: { xs: "left", md: "left", lg: "left" },
						}}>
						FEATURES
					</TextField>
					<TextField
						variant={"h6"}
						sx={{
							textAlign: { xs: "left", md: "left", lg: "left" },
						}}>
						Top 10 casse-croute
					</TextField>
				</DashboardTextWrapper>
				<DashboardTextWrapper>
					<TextField
						variant={"h6"}
						sx={{
							textAlign: { xs: "left", md: "left", lg: "left" },
						}}>
						NOTES
					</TextField>
					<TextField
						variant={"h6"}
						sx={{
							textAlign: { xs: "left", md: "left", lg: "left" },
						}}>
						It has survived not only five centuries, but also the leap into
						electronic typesetting, remaining essentially unchanged. It was
						popularised in the 1960s with the release of Letraset sheets
						containing Lorem Ipsum passages, and more recently with desktop
						publishing software like Aldus PageMaker including versions of Lorem
						Ipsum.
					</TextField>
				</DashboardTextWrapper>
				<StyledLink href={ADMIN_DASHBOARD_RESTAURANT_EDIT}>
					<TextField variant={"p"}>EDIT NOTES</TextField>
				</StyledLink>
			</DashboardSection>
			<DashboardSection
				customStyle={{
					display: "flex",
					flexWrap: "wrap",
					mt: "10px",
					borderBottom: "solid",
					borderColor: theme.palette.color_grey,
				}}>
				<StyledDashBoardSectionTitleContainer>
					<TextField
						variant={"h3"}
						sx={{
							textAlign: { xs: "center", md: "center", lg: "left" },
						}}>
						Client Information
					</TextField>
				</StyledDashBoardSectionTitleContainer>
				<DashboardSubSection>
					<TextField
						variant={"h4"}
						sx={{
							textAlign: { xs: "center", md: "center", lg: "left" },
						}}>
						GENERAL INFORMATION
					</TextField>
					{generalInfo.map((info) => (
						<DashboardTextWrapper>
							<TextField
								variant={"h6"}
								sx={{
									textAlign: { xs: "left", md: "left", lg: "left" },
								}}>
								{info.title}
							</TextField>
							<TextField variant={"p"}>{info.value}</TextField>
						</DashboardTextWrapper>
					))}
				</DashboardSubSection>
				<DashboardSubSection>
					<TextField
						variant={"h4"}
						sx={{
							textAlign: { xs: "center", md: "center", lg: "left" },
						}}>
						STAFF INFORMATION
					</TextField>
					{staffInfo.map((info) => (
						<DashboardTextWrapper>
							<TextField
								variant={"h6"}
								sx={{
									textAlign: { xs: "left", md: "left", lg: "left" },
								}}>
								{info.title}
							</TextField>
							<TextField variant={"p"}>{info.value}</TextField>
						</DashboardTextWrapper>
					))}
				</DashboardSubSection>
			</DashboardSection>
			<DashboardSection
				customStyle={{
					display: "flex",
					flexWrap: "wrap",
					mt: "10px",
					borderBottom: "solid",
					borderColor: theme.palette.color_grey,
				}}>
				<DashboardSubSection>
					<TextField
						variant={"h4"}
						sx={{
							textAlign: { xs: "center", md: "center", lg: "left" },
						}}>
						LOCATION
					</TextField>
					<DashboardTextWrapper>
						<TextField
							variant={"h6"}
							sx={{
								textAlign: { xs: "left", md: "left", lg: "left" },
							}}>
							Address:
						</TextField>
						<TextField variant={"p"}>{dummyData[0].address}</TextField>
					</DashboardTextWrapper>
					<DashboardTextWrapper
						customStyle={{
							flexDirection: "column",
							alignItems: "flexStart",
						}}>
						<TextField
							variant={"h6"}
							sx={{
								textAlign: { xs: "left", md: "left", lg: "left" },
							}}>
							Hours:
						</TextField>
						{dummyData[0].hours.map((openinghours) => (
							<TextField variant={"p"}>{openinghours}</TextField>
						))}
					</DashboardTextWrapper>
				</DashboardSubSection>
				<DashboardSubSection customStyle={{ width: "100%" }}>
					<img src={defaultImage} width={"100%"}></img>
				</DashboardSubSection>
			</DashboardSection>
			<DashboardSection
				customStyle={{
					display: "flex",
					flexWrap: "wrap",
					mt: "10px",
				}}>
				<DashboardSubSection customStyle={{ width: "100%" }}>
					<TextField
						variant={"h4"}
						sx={{
							textAlign: { xs: "center", md: "center", lg: "left" },
						}}>
						OTHER INFORMATION
					</TextField>
					{otherInfo.map((info) => (
						<DashboardTextWrapper>
							<TextField
								variant={"h6"}
								sx={{
									textAlign: { xs: "left", md: "left", lg: "left" },
								}}>
								{info.title}
							</TextField>
							<TextField variant={"p"}>{info.value}</TextField>
						</DashboardTextWrapper>
					))}
				</DashboardSubSection>
			</DashboardSection>
			<DashboardSection>
				{" "}
				<StyledDashBoardSectionTitleContainer>
					<TextField
						variant={"h3"}
						sx={{
							textAlign: { xs: "center", md: "center", lg: "left" },
						}}>
						Tastet Information
					</TextField>
				</StyledDashBoardSectionTitleContainer>
				<DashboardSubSection>
					<DashboardTextWrapper>
						<TextField
							variant={"h6"}
							sx={{
								textAlign: { xs: "left", md: "left", lg: "left" },
							}}>
							Merchant type:
						</TextField>
						<TextField variant={"p"}>Aldus PageMaker</TextField>
					</DashboardTextWrapper>
					<DashboardTextWrapper>
						<TextField
							variant={"h6"}
							sx={{
								textAlign: { xs: "left", md: "left", lg: "left" },
							}}>
							Cuisine:
						</TextField>
						<TextField variant={"p"}>Aldus PageMaker</TextField>
					</DashboardTextWrapper>
					<TextField
						variant={"h6"}
						sx={{
							textAlign: { xs: "left", md: "left", lg: "left" },
						}}>
						FOOD
					</TextField>
					<DashboardTextWrapper>
						<TextField
							variant={"h6"}
							sx={{
								textAlign: { xs: "left", md: "left", lg: "left" },
							}}>
							Cover size:
						</TextField>
						<TextField variant={"p"}>Aldus PageMaker</TextField>
					</DashboardTextWrapper>
					<DashboardTextWrapper>
						<TextField
							variant={"h6"}
							sx={{
								textAlign: { xs: "left", md: "left", lg: "left" },
							}}>
							The dishes served are:
						</TextField>
						<TextField variant={"p"}>
							{" "}
							It has survived not only five centuries, but also the leap into
							electronic typesetting, remaining essentially unchanged. It was
							popularised in the 1960s with the release of Letraset sheets
							containing Lorem Ipsum passages
						</TextField>
					</DashboardTextWrapper>

					<TextField
						variant={"h6"}
						sx={{
							textAlign: { xs: "left", md: "left", lg: "left" },
						}}>
						ALCOOL
					</TextField>
					<DashboardTextWrapper>
						<TextField
							variant={"h6"}
							sx={{
								textAlign: { xs: "left", md: "left", lg: "left" },
							}}>
							The types of drink are:
						</TextField>
						<TextField variant={"p"}>
							{" "}
							It has survived not only five centuries, but also the leap into
							electronic typesetting, remaining essentially unchanged. It was
							popularised in the 1960s with the release of Letraset sheets
							containing Lorem Ipsum passages
						</TextField>
					</DashboardTextWrapper>
					<TextField
						variant={"h6"}
						sx={{
							textAlign: { xs: "left", md: "left", lg: "left" },
						}}>
						SERVICE AND MORE
					</TextField>
					<DashboardTextWrapper>
						<TextField
							variant={"h6"}
							sx={{
								textAlign: { xs: "left", md: "left", lg: "left" },
							}}>
							The address offers:
						</TextField>
						<TextField variant={"p"}>
							{" "}
							It has survived not only five centuries, but also the leap into
							electronic typesetting, remaining essentially unchanged. It was
							popularised in the 1960s with the release of Letraset sheets
							containing Lorem Ipsum passages
						</TextField>
					</DashboardTextWrapper>
					<DashboardTextWrapper>
						<TextField
							variant={"h6"}
							sx={{
								textAlign: { xs: "left", md: "left", lg: "left" },
							}}>
							The address is open on:
						</TextField>
						<TextField variant={"p"}>
							{" "}
							It has survived not only five centuries, but also the leap into
							electronic typesetting, remaining essentially unchanged. It was
							popularised in the 1960s with the release of Letraset sheets
							containing Lorem Ipsum passages
						</TextField>
					</DashboardTextWrapper>
					<DashboardTextWrapper>
						<TextField
							variant={"h6"}
							sx={{
								textAlign: { xs: "left", md: "left", lg: "left" },
							}}>
							The address is:
						</TextField>
						<TextField variant={"p"}>
							{" "}
							It has survived not only five centuries, but also the leap into
							electronic typesetting, remaining essentially unchanged. It was
							popularised in the 1960s with the release of Letraset sheets
							containing Lorem Ipsum passages
						</TextField>
					</DashboardTextWrapper>
				</DashboardSubSection>
				<DashboardSubSection>
					<TextField
						variant={"h6"}
						sx={{
							textAlign: { xs: "left", md: "left", lg: "left" },
						}}>
						OWNER
					</TextField>
					<DashboardTextWrapper>
						<TextField
							variant={"h6"}
							sx={{
								textAlign: { xs: "left", md: "left", lg: "left" },
							}}>
							Is the owner nice:
						</TextField>
						<TextField variant={"p"}>yes</TextField>
					</DashboardTextWrapper>
					<DashboardTextWrapper>
						<TextField
							variant={"h6"}
							sx={{
								textAlign: { xs: "left", md: "left", lg: "left" },
							}}>
							Is it a small owner?:
						</TextField>
						<TextField variant={"p"}>5pieds10</TextField>
					</DashboardTextWrapper>

					<TextField
						variant={"h6"}
						sx={{
							textAlign: { xs: "left", md: "left", lg: "left" },
						}}>
						ATMOSPHERE
					</TextField>
					{atmosphereInfo.map((info) => (
						<DashboardTextWrapper>
							<TextField
								variant={"h6"}
								sx={{
									textAlign: { xs: "left", md: "left", lg: "left" },
								}}>
								{info.title}
							</TextField>
							<TextField variant={"p"}>{info.value}</TextField>
						</DashboardTextWrapper>
					))}

					<StyledLink href={ADMIN_DASHBOARD_RESTAURANT_EDIT}>
						<TextField variant={"p"}>EDIT CRITERIAS</TextField>
					</StyledLink>
				</DashboardSubSection>
			</DashboardSection>
		</>
	);
};
export default AdminRestaurantDetailsPage;
