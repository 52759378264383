import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Link } from '@mui/material';
import { Route } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import { useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";


const BreadCrumbs = ({ href, state, children, customStyle }) => {
    const navigate = useNavigate();
    const theme = useTheme();
    const location = useLocation();
    const { t, i18n } = useTranslation();

    const defaultStyle = {
        textDecoration: "none",
        cursor: "pointer",
        color: theme.palette.color_green,
        display: 'flex',
        alignItems: 'center',
        position: 'absolute',
        top: { xs: 0, sm: 0, md: '40px', lg: '60px', xl: '60px' }
    }

    // xs, extra-small: 0px
    // sm, small: 600px
    // md, medium: 900px
    // lg, large: 1200px
    // xl, extra-large: 1536px
    const handleNavigation = () => {
        navigate(href, { state: state });
    };


    return (<Link sx={{ ...defaultStyle, ...customStyle }} onClick={() => handleNavigation()}><ArrowBackIcon />{children}</Link>)
}

export default BreadCrumbs;