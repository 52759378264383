import { useState, useEffect } from "react";

import TextField from "../../../components/text-fields/TextField";
import StyledInput from "../../../components/inputs/styled-input/StyledInput";
import StyledLink from "../../../components/links/styled-link/StyledLink";
import DashboardSection from "../../../components/sections/dashboard-section/DashboardSection";
import DropDownSearchInput from "../../../components/inputs/dropdown/DropDownSearchInput";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import StyledButton from "../../../components/buttons/StyledButtonComponent";
import AccordionComponent from "../../../components/accordion/AccordionComponent";
import DropDownMenu from "../../../components/drop-down-menu/DropDownMenuComponent";
import Box from "@mui/material/Box";
import SortIcon from "@mui/icons-material/Sort";
import Divider from "@mui/material/Divider";
import ListComponent from "../../../components/list/ListComponent";
import ListItemComponent from "../../../components/list-item/ListItemComponent";
import FilterAccordion from "../../../components/accordion/FilterAccordionComponent";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import RoundButton from "../../../components/buttons/RoundButtonComponent";
import { apiUrl } from "../../../config/urls";
import { useTheme } from "@mui/material";
import { useListingSearch } from "../../../contexts/authContext/listingSearchContext";
const axios = require("axios");
const AdminSearchTab = ({}) => {
	const {
		setSearchTerm,
		setSuggestions,
		getACSuggestions,
		getRestaurants,
		suggestions,
		initialSuggestions,
		searchTerm,
	} = useListingSearch();
	const theme = useTheme();

	useEffect(() => {
		if (searchTerm.length > 3) {
			console.log("here");
			getACSuggestions(searchTerm);
		} else if (searchTerm === "") {
			setSuggestions([]);
		}
		return;
	}, [searchTerm]);

	useEffect(() => {
		getRestaurants();
		return () => getRestaurants();
	}, []);

	const categoryFilters = [
		{ category: "all" },
		{ category: "restaurant" },
		{ category: "cafe" },
		{ category: "bar" },
		{ category: "bakery" },
		{ category: "high-end" },
		{ category: "byob" },
		{ category: "wine-bar" },
	];

	const regionFilters = [
		{ category: "Old-Port" },
		{ category: "Outremont" },
		{ category: "Plateau" },
	];

	const cuisineFilters = [
		{ category: "Old-Port" },
		{ category: "Outremont" },
		{ category: "Plateau" },
	];

	const statusFilters = [
		{ category: "Old-Port" },
		{ category: "Outremont" },
		{ category: "Plateau" },
	];

	const filterByWrapper = {
		position: "relative",
		display: "flex",
		alignItems: "center",
		flexWrap: "wrap",
		borderRadius: "5px",
	};

	return (
		<Box sx={{ width: { xs: "100%", md: "70%", lg: "70%", xl: "70%" } }}>
			<TextField variant={"h1"} sx={{ fontWeight: "900", fontSize: "2rem" }}>
				Welcome, Frodo!
			</TextField>
			<TextField variant={"2"} sx={{ fontWeight: "900", fontSize: "1.25rem" }}>
				What Address are you looking for today?
			</TextField>
			<DropDownSearchInput
				isDashboard={true}
				isList={false}
				isAdmin={true}
				tableHeaders={["Name", "View"]}
				setSearchTerm={setSearchTerm}
				initialSuggestions={initialSuggestions}
				suggestions={suggestions}
				searchTerm={searchTerm}></DropDownSearchInput>
			<Box>
				{categoryFilters.map((filter) => (
					<RoundButton
						text={filter.category}
						key={filter.category}></RoundButton>
				))}
			</Box>
			<Box sx={{ ...filterByWrapper }}>
				<FilterAccordion customStyle={{}}>
					<AccordionSummary
						aria-controls="panel1a-content"
						id="panel1a-header"
						expandIcon={<ExpandMoreIcon />}>
						<FilterAltOutlinedIcon />
						<TextField>Filter by:</TextField>
						<TextField>REGION CUISINE STATUS</TextField>
					</AccordionSummary>
					<AccordionDetails>
						<Box>
							<TextField>REGION</TextField>
							{regionFilters.map((subfilter) => (
								<FormControlLabel
									key={subfilter.category}
									control={<Checkbox defaultChecked />}
									label={subfilter.category}
								/>
							))}
						</Box>
						<Box>
							<TextField>CUISINE</TextField>
							{cuisineFilters.map((subfilter) => (
								<FormControlLabel
									key={subfilter.category}
									control={<Checkbox defaultChecked />}
									label={subfilter.category}
								/>
							))}
						</Box>
						<Box>
							<TextField>STATUS</TextField>
							{statusFilters.map((subfilter) => (
								<FormControlLabel
									key={subfilter.category}
									control={<Checkbox defaultChecked />}
									label={subfilter.category}
								/>
							))}
						</Box>
						<TextField>
							Lorem ipsum dolor sit amet, consectetur adipiscing elit.
							Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
							eget.
						</TextField>
					</AccordionDetails>
				</FilterAccordion>

				<FilterAccordion customStyle={{}}>
					<AccordionSummary
						aria-controls="panel1a-content"
						id="panel1a-header"
						expandIcon={<ExpandMoreIcon />}>
						<SortIcon />
						<TextField
							variant={"h6"}
							sx={{ fontWeight: "900", fontSize: "1.25rem" }}>
							Sort By:
						</TextField>
					</AccordionSummary>
					<AccordionDetails>
						<TextField>
							Lorem ipsum dolor sit amet, consectetur adipiscing elit.
							Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
							eget.
						</TextField>
					</AccordionDetails>
				</FilterAccordion>
			</Box>
			<ListComponent
				tableHeaders={[
					"Name",
					"Address",
					"Merchant type",
					"Region",
					"Status",
					"View",
				]}
				isVisible={true}
				darkMode={true}
				isAdmin={true}></ListComponent>
		</Box>
	);
};
export default AdminSearchTab;
