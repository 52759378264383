import HelpIcon from "@mui/icons-material/Help";
import IconButton from "@mui/material/IconButton";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
const LightTooltip = styled(({ className, ...props }) => (
	<Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
	[`& .${tooltipClasses.tooltip}`]: {
		//backgroundColor: theme.palette.common.white,
		color: "rgba(0, 0, 0, 0.87)",
		boxShadow: theme.shadows[1],
		fontSize: 11,
		background: "rgb(246,242,235, 1)",
		lineHeight: "2rem"
	},
}));

//sx={{}}
const BasicTooltip = ({ text }) => {
	return (
		<LightTooltip title={text} placement="bottom" >
			<IconButton
				sx={{
					marginTop: "-5px",
				}}>
				<HelpIcon
					sx={{
						color: "black",
					}}
				/>
			</IconButton>
		</LightTooltip>
	);
};

export default BasicTooltip;
