const Page404 = ({}) => {
	return (
		<div
			style={{
				marginTop: "41px",
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
			}}>
			PAGE 404
		</div>
	);
};
export default Page404;
