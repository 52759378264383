import TextareaAutosize from "@mui/material/TextareaAutosize";
import StyledButton from "../../buttons/StyledButtonComponent";
import Box from "@mui/material/Box";

const NotesForm = ({}) => {
	const buttonWrapperStyle = {
		display: "flex",
		width: "100%",
		justifyContent: { xs: "center", md: "center", lg: "flex-end" },
	};

	return (
		<>
			<TextareaAutosize
				placeholder="Empty"
				minRows={20}
				style={{ maxWidth: "100%", width: "100%" }}></TextareaAutosize>
			<Box sx={{ ...buttonWrapperStyle }}>
				<StyledButton>SAVE NOTES</StyledButton>
			</Box>
		</>
	);
};
export default NotesForm;
