import React, { Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import {
	HOMEPAGE,
	LOGIN,
	ADDRESS_CLAIM_1,
	ADDRESS_CLAIM_2,
	ADDRESS_CLAIM_3,
	MERCHANT_DASHBOARD,
	MERCHANT_DASHBOARD_HOME_TAB,
	MERCHANT_DASHBOARD_COMMUNICATIONS_TAB,
	MERCHANT_DASHBOARD_ADDADDRESS_TAB,
	MERCHANT_DASHBOARD_ADDADDRESS_SUCCESS,
	MERCHANT_DASHBOARD_RESTAURANT_DETAILS,
	MERCHANT_DASHBOARD_IMAGE_PURCHASE,
	MERCHANT_DASHBOARD_RESTAURANT_EDIT,
	ADMIN_DASHBOARD,
	ADMIN_DASHBOARD_SEARCH_TAB,
	ADMIN_DASHBOARD_COMMUNITCATIONS_TAB,
	ADMIN_DASHBOARD_LIST_TAB,
	ADMIN_DASHBOARD_RESTAURANT_DETAILS,
	ADMIN_DASHBOARD_RESTAURANT_EDIT,
	ADMIN_DASHBOARD_LIST_DETAILS,
	ADMIN_DASHBOARD_LIST_EDIT,
	ADMIN_DASHBOARD_LIST_CREATE,
} from "../src/config/routes";
import Header from "../src/components/header/Header";

//import HomePage from "./pages/HomePage/HomePage";
import MerchantDashBoardPage from "./pages/MerchantDashboard/MerchantDashBoardPage";
import AddressClaimStepOnePage from "./pages/AddressClaim/StepOne/AddressClaimStepOnePage";
import AddressClaimStepTwoPage from "./pages/AddressClaim/StepTwo/AddressClaimStepTwoPage";
import AddressClaimStepThreePage from "../src/pages/AddressClaim/StepThree/AddressClaimStepThreePage";
import LoginPage from "./pages/Login/LoginPage";
import MerchantAddAddressTabPage from "./pages/MerchantDashboard/AddAddressTab/MerchantAddAddressTabPage";
import MerchantAddAddressDashboardSuccessPage from "./pages/MerchantDashboard/AddAddressTab/SuccessPage/MerchantAddAddressDashboardSuccessPage";
import MerchantCommunicationsTabPage from "./pages/MerchantDashboard/CommunicationsTab/MerchantCommunicationsTabPage";
import MerchantHomeTabPage from "./pages/MerchantDashboard/HomeTab/MerchantHomeTabPage";
import MerchantRestaurantDetailsPage from "./pages/MerchantDashboard/RestaurantDetails/MerchantRestaurantDetailsPage";
import MerchantImagePurchasePage from "./pages/MerchantDashboard/ImagePurchase/MerchantImagePurchasePage";
import MerchantRestaurantEditPage from "./pages/MerchantDashboard/RestaurantEdit/MerchantRestaurantEditPage";
import AdminDashBoardPage from "./pages/AdminDashboard/AdminDashBoardPage";
import AdminSearchTab from "./pages/AdminDashboard/SearchTab/AdminSearchTab";
import AdminCommunicationsTab from "./pages/AdminDashboard/CommunicationsTab/AdminCommunicationsTab";
import AdminListTab from "./pages/AdminDashboard/ListTab/AdminListTab";
import AdminRestaurantDetailsPage from "./pages/AdminDashboard/RestaurantDetails/AdminRestaurantDetailsPage";
import AdminRestaurantEditPage from "./pages/AdminDashboard/RestaurantEdit/AdminRestaurantEditPage";
import AdminListDetailsPage from "./pages/AdminDashboard/ListTab/ListDetails/AdminListDetailsPage";
import AdminListEditPage from "./pages/AdminDashboard/ListTab/ListEdit/AdminListEditPage";
import AdminListCreatePage from "./pages/AdminDashboard/ListTab/ListCreate/AdminListCreatePage";
import Page404 from "./pages/404/Page404";
import BigLoader from "./components/loaders/BigLoader";

import { ListingSearchProvider } from "./contexts/authContext/listingSearchContext";
const HomePage = React.lazy(() => import("./pages/HomePage/HomePage"));
const App = () => {
	return (
		<Suspense fallback={<BigLoader show />}>
			<Header />
			<Routes>
				<Route exact path={HOMEPAGE} element={<HomePage />} />
				<Route exact path={LOGIN} element={<LoginPage />} />

				<Route path={MERCHANT_DASHBOARD} element={<MerchantDashBoardPage />}>
					<Route
						path={MERCHANT_DASHBOARD_HOME_TAB}
						element={<MerchantHomeTabPage />}></Route>

					<Route
						path={`${MERCHANT_DASHBOARD_RESTAURANT_DETAILS}:publicId`}
						element={
							<>
								<MerchantRestaurantDetailsPage />
							</>
						}></Route>
					<Route
						path={`${MERCHANT_DASHBOARD_RESTAURANT_EDIT}:publicId`}
						element={<MerchantRestaurantEditPage />}></Route>
					<Route path={`${MERCHANT_DASHBOARD_IMAGE_PURCHASE}`}
						element={<MerchantImagePurchasePage />}>

					</Route>
				</Route>

				<Route path={ADMIN_DASHBOARD} element={<AdminDashBoardPage />}>
					<Route element={<ListingSearchProvider />}>
						<Route
							path={ADMIN_DASHBOARD_SEARCH_TAB}
							element={<AdminSearchTab />}></Route>
					</Route>
					<Route
						path={ADMIN_DASHBOARD_COMMUNITCATIONS_TAB}
						element={<AdminCommunicationsTab />}></Route>
					<Route
						path={ADMIN_DASHBOARD_LIST_TAB}
						element={<AdminListTab />}></Route>
					<Route
						path={ADMIN_DASHBOARD_RESTAURANT_DETAILS}
						element={<AdminRestaurantDetailsPage />}></Route>
					<Route
						path={ADMIN_DASHBOARD_RESTAURANT_EDIT}
						element={<AdminRestaurantEditPage />}></Route>
					<Route
						path={ADMIN_DASHBOARD_LIST_DETAILS}
						element={<AdminListDetailsPage />}></Route>
					<Route
						path={ADMIN_DASHBOARD_LIST_EDIT}
						element={
							<>
								<AdminListEditPage />
							</>
						}></Route>
					<Route
						path={ADMIN_DASHBOARD_LIST_CREATE}
						element={<AdminListCreatePage />}></Route>
				</Route>
				<Route path="*" element={<Page404 />} />
			</Routes>
		</Suspense>
	);
};

export default App;

/**
 * 
 * 
 * 	<Route element={<ListingSearchProvider />}>
					<Route
						exact
						path={ADDRESS_CLAIM_1}
						element={<AddressClaimStepOnePage />}
					/>

					<Route
						exact
						path={ADDRESS_CLAIM_2}
						element={<AddressClaimStepTwoPage />}
					/>
				</Route>
				<Route
					exact
					path={ADDRESS_CLAIM_3}
					element={<AddressClaimStepThreePage />}
				/>
 * 
 * 
 * 
 * 
 * 
 * 
 * 
 * 	<Route
						path={MERCHANT_DASHBOARD_COMMUNICATIONS_TAB}
						element={<MerchantCommunicationsTabPage />}></Route>
					<Route
						path={MERCHANT_DASHBOARD_ADDADDRESS_TAB}
						element={
							<>
								<MerchantAddAddressTabPage />
							</>
						}></Route>
					<Route
						path={MERCHANT_DASHBOARD_ADDADDRESS_SUCCESS}
						element={<MerchantAddAddressDashboardSuccessPage />}></Route>
 * 
 * 
 * 
 * 	<Route
						path={MERCHANT_DASHBOARD_IMAGE_PURCHASE}
						element={<MerchantImagePurchasePage />}></Route>
 * 
 */
