/* eslint-disable no-lone-blocks */
import TableRow from "@mui/material/TableRow";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import StyledLink from "../links/styled-link/StyledLink";
import TextField from "../text-fields/TextField";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import {
	ADDRESS_CLAIM_2,
	MERCHANT_DASHBOARD_ADDADDRESS_SUCCESS,
	ADMIN_DASHBOARD_RESTAURANT_DETAILS,
	ADMIN_DASHBOARD_RESTAURANT_EDIT,
	ADMIN_DASHBOARD_LIST_DETAILS,
	ADMIN_DASHBOARD_LIST_EDIT,
} from "../../config/routes";
const ListItemComponent = ({ data, isDashboard, isAdmin, isList }) => {
	const handleDelete = (n) => {
		document.getElementById("tableRow").deleteRow(n);
	};

	/**
	 * 
	 * 	{Object.values(data).map((data) => (
					<TableCell align="right">
						<TextField variant="p">{data}</TextField>
					</TableCell>
				))}
	 * 
	 * 
	 * 
	 * 
	 */
	return (
		<>
			<TableRow id="tableRow">
				<TableCell sx={{ width: "50%" }} align="center">
					<TextField sx={{ maxWidth: "50%" }} variant="p">
						{data.name}
					</TextField>
				</TableCell>

				{data.isClaimed && !isAdmin ? (
					<TableCell align="right">
						<TextField variant="p" sx={{ color: "red" }}>
							CLAIMED
						</TextField>
					</TableCell>
				) : !data.isClaimed && !isAdmin ? (
					<TableCell align="right">
						<StyledLink
							href={
								!isDashboard
									? ADDRESS_CLAIM_2
									: MERCHANT_DASHBOARD_ADDADDRESS_SUCCESS
							}>
							<TextField variant="p">CLAIM</TextField>
						</StyledLink>
					</TableCell>
				) : isAdmin ? (
					<TableCell
						sx={{ display: "flex", width: "100%", justifyContent: "center" }}>
						<StyledLink
							customLinkStyle={{ width: "200px", m: "0px" }}
							href={ADMIN_DASHBOARD_RESTAURANT_DETAILS.replace(
								":publicId",
								data.publicId
							)}>
							<TextField variant="p">VIEW</TextField>
						</StyledLink>
					</TableCell>
				) : (
					<></>
				)}
			</TableRow>
		</>
	);
};
export default ListItemComponent;
