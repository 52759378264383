import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import DashboardTextWrapper from "../sections/dashbord-text-wrapper/DashboardTextWrapper";
import TextField from "../text-fields/TextField";
import Checkbox from "@mui/material/Checkbox";
const PhotoCard = ({ data, index, handleSelectedPicture }) => {
	const [displayFullPicture, setDisplayFullPicture] = useState(false)
	const defaultPhotoCardStyle = {
		margin: "15px"


	};
	const textWrapper = {
		display: "flex",
		justifyContent: "space-between",
	};

	const imgWrapperStyle = {
		width: "300px",
		maxWidth: "300px",
		height: "300px",
		position: "relative"
	}

	const fullImageStyle = {
		position: "absolute",
		top: "0",
		bottom: "0",
		width: "100vw"

	}

	const displayFullImage = () => {
		setDisplayFullPicture(!displayFullPicture)
	}


	return (
		<Box sx={{ ...defaultPhotoCardStyle }}>
			<Box sx={{ ...imgWrapperStyle }} onClick={() => displayFullImage()} >
				<Checkbox sx={{ position: "absolute", top: "0", right: "0", color: "white" }} onClick={(e) => handleSelectedPicture(data.url, index, e)}></Checkbox>
				<img style={{ width: "100%", height: "100%", objectFit: "cover" }} src={data.url}></img>
			</Box>
			{/* {displayFullPicture && <Box sx={{ ...fullImageStyle }}>
				<img style={{ width: "100%", height: "100%", objectFit: "cover" }} src={data.url}></img>
			</Box>} */}
			{/* <DashboardTextWrapper customStyle={textWrapper}>
				{index === 0 ? (
					<TextField variant={"p"}>Cover photo</TextField>
				) : (
					<TextField variant={"p"}>Photo {index}</TextField>
				)}

				<TextField variant={"p"}>{data.price}</TextField>
			</DashboardTextWrapper>
			<TextField variant={"p"}>By Artist</TextField> */}
		</Box>
	);
};
export default PhotoCard;
