import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";

import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import ListHeaderComponent from "../list-header/ListHeaderComponent";
import ListItemComponent from "../list-item/ListItemComponent";

const ListComponent = ({
	customStyle,
	showSuggestions,
	tableHeaders,
	items,
	isDashboard,
	darkMode,
	isAdmin,
	isList,
}) => {
	return (
		<TableContainer
			component={Paper}
			sx={{
				display: !items ? "none" : null,
				mt: !items ? "none" : "20px",
				...customStyle,
			}}>
			<Table sx={{ maxWidth: "100%" }} aria-label="customized table">
				<ListHeaderComponent
					tableHeaders={tableHeaders}
					darkMode={darkMode}></ListHeaderComponent>
				<TableBody>
					{items?.map((data, index) => (
						<ListItemComponent
							key={index}
							data={data}
							isDashboard={isDashboard}
							isAdmin={isAdmin}
							isList={isList}></ListItemComponent>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	);
};
export default ListComponent;
