import { useState } from "react";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material";
import TabLink from "../tabs/TabIconComponent";
import StyledButton from "../../buttons/StyledButtonComponent";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}>
			{value === index && (
				<Box sx={{ p: 3 }}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.number.isRequired,
	value: PropTypes.number.isRequired,
};

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		"aria-controls": `simple-tabpanel-${index}`,
	};
}

const TabsContainer = ({ tabs, isMetrics, customStyle }) => {
	const theme = useTheme();
	const defaultTabsContainerStyle = {
		padding: "15px",
		backgroundColor: theme.palette.color_green,
		display: "flex",
		flexDirection: { xs: "row", md: "row", lg: "column" },
		justifyContent: "space-around",
		top: "80px",
		position: { xs: "sticky", md: "sticky", lg: "fixed" },
		height: { xs: "100px", sm: '100px', md: "100px", lg: "-webkit-fill-available", xl: "-webkit-fill-available" },
		zIndex: "2"
	};

	const [value, setValue] = useState("1");

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	/**
	 FIX RENDER LOGIC


		  <Box sx={{ ...defaultTabsContainerStyle, ...customStyle }}>
					<StyledButton
						sx={{
							color: theme.palette.color_yellow,
							backgroundColor: "none",
							mb: "20px",
						}}
						fullWidth={true}
						variant="text">
						All
					</StyledButton>
					{tabs.map((tab, index) => (
						<StyledButton
							sx={{
								color: theme.palette.color_yellow,
								backgroundColor: "none",
								mb: "20px",
							}}
							fullWidth={true}
							variant="text">
							{tab.displayName}
						</StyledButton>
					))}
				</Box>
	 */

	return (
		<>
			{!isMetrics ? (
				<Box sx={{ ...defaultTabsContainerStyle }}>
					{tabs?.map((tab, index) => (
						<TabLink key={tab.label} href={tab.path} label={tab.label}>
							{tab.icon}
						</TabLink>
					))}
				</Box>
			) : (
				<Box sx={{ width: "100%" }}>
					<Box
						sx={{
							borderBottom: 1,
							display: "flex",
							backgroundColor: theme.palette.color_green,
						}}>
						<Tabs
							sx={{ color: theme.palette.color_yellow }}
							value={value}
							onChange={handleChange}
							aria-label="basic tabs example">
							<Tab
								label="Item One"
								sx={{ color: theme.palette.color_grey }}
								{...a11yProps(0)}
							/>
							<Tab
								label="Item Two"
								sx={{ color: theme.palette.color_grey }}
								{...a11yProps(1)}
							/>
							<Tab
								label="Item Three"
								sx={{ color: theme.palette.color_grey }}
								{...a11yProps(2)}
							/>
						</Tabs>
					</Box>
					<TabPanel value={value} index={0}>
						Item One
					</TabPanel>
					<TabPanel value={value} index={1}>
						Item Two
					</TabPanel>
					<TabPanel value={value} index={2}>
						Item Three
					</TabPanel>
				</Box>
			)}
		</>
	);
};
export default TabsContainer;
