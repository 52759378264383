import { createContext, useContext, useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { apiUrl } from "../../config/urls";
const axios = require("axios");

const ListingSearchContext = createContext(null);

const ListingSearchProvider = (props) => {
	const [searchTerm, setSearchTerm] = useState("");
	const [suggestions, setSuggestions] = useState([]);
	const [initialSuggestions, setInitialSeggestions] = useState([]);
	const getACSuggestions = async (searchTerm) => {
		let autocomplete_names_endpoint = `${apiUrl}/merchant/find/${searchTerm}`;
		try {
			await axios.get(autocomplete_names_endpoint, {}).then((response) => {
				setSuggestions(response.data.names);
			});
		} catch (error) {
			console.error(error);
		}
	};

	const getRestaurants = async () => {
		try {
			await axios.get(`${apiUrl}/merchant/listings`, {}).then((response) => {
				setInitialSeggestions(response.data);
			});
		} catch (error) {
			console.error(error);
		}
	};

	return (
		<ListingSearchContext.Provider
			value={{
				setSearchTerm,
				setSuggestions,
				getACSuggestions,
				getRestaurants,
				suggestions,
				initialSuggestions,
				searchTerm,
			}}
			{...props}>
			<Outlet />
		</ListingSearchContext.Provider>
	);
};

const useListingSearch = () => useContext(ListingSearchContext);
export { ListingSearchProvider, useListingSearch };
