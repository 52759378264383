import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import { useNavigate } from "react-router-dom";

const TabLink = ({ href, label, children }) => {
	const navigate = useNavigate();
	const defaultBoxStyle = {
		padding: "6px 8px",
		m: { xs: "0 0 10px 0", md: "0 0 10px 0" },
	};

	const defaultLinkStyle = {
		display: "flex",
		flexDirection: { xs: "column", md: "column" },
		alignItems: { xs: "center", md: "center" },
		color: "white",
		cursor: "pointer"
	};
	const handleNavigation = () => {
		navigate(href);
	};

	return (
		<Box sx={{ ...defaultBoxStyle }}>
			<Link
				sx={{ ...defaultLinkStyle }}
				underline="none"
				onClick={() => {
					handleNavigation();
				}}>
				{children}
				<label style={{ cursor: "pointer" }}>{label}</label>
			</Link>
		</Box>
	);
};
export default TabLink;
