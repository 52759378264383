import { Outlet, Navigate } from "react-router-dom";
import TabsContainer from "../../components/dashboard/tabs-panel/TabPanelComponent";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import TocOutlinedIcon from "@mui/icons-material/TocOutlined";
import ManageSearchOutlinedIcon from "@mui/icons-material/ManageSearchOutlined";
import Box from "@mui/material/Box";
import {
	ADMIN_DASHBOARD_SEARCH_TAB,
	ADMIN_DASHBOARD_COMMUNITCATIONS_TAB,
	ADMIN_DASHBOARD_LIST_TAB,
} from "../../config/routes";
import DashBoardPageLayout from "../../components/sections/dashboard-page-layout/DashBoardPageLayout";
import { useAuth } from "../../contexts/authContext/authContext";
import BigLoader from "../../components/loaders/BigLoader";

const AdminDashBoardPage = ({ children }) => {
	const iconsStyle = { color: "white" };
	const tabLinks = [
		{
			label: "SEARCH",
			icon: <ManageSearchOutlinedIcon style={iconsStyle} />,
			path: ADMIN_DASHBOARD_SEARCH_TAB,
		},
		{
			label: "MESSAGES",
			icon: <EmailOutlinedIcon style={iconsStyle} />,
			path: ADMIN_DASHBOARD_COMMUNITCATIONS_TAB,
		},
		{
			label: "LIST",
			icon: <TocOutlinedIcon style={iconsStyle} />,
			path: ADMIN_DASHBOARD_LIST_TAB,
		},
	];
	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: { xs: "column", md: "column", lg: "row" },
			}}>
			<TabsContainer tabs={tabLinks}></TabsContainer>
			<DashBoardPageLayout>
				<AdminPrivateRoute>
					{children} <Outlet />
				</AdminPrivateRoute>
			</DashBoardPageLayout>
		</Box>
	);
};
export default AdminDashBoardPage;
const AdminPrivateRoute = ({ children }) => {
	const auth = useAuth();
	const userSession = sessionStorage.getItem("user");
	if (auth.user === null && userSession) {
		return <BigLoader />;
	}
	if (auth?.user !== null && (auth?.user?.editor || auth?.user?.admin)) {
		return children;
	} else {
		return <Navigate to="/" />;
	}
};
