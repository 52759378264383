/* eslint-disable default-case */
import { useParams, useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useAuth } from "../../../contexts/authContext/authContext";
import { Paper } from "@mui/material";
import { useTheme } from "@mui/material";
import StyledDashBoardSectionTitleContainer from "../../../components/sections/styled-dashboard-section-title/StyledDashBoardSectionTitleContainer";
import DashboardSection from "../../../components/sections/dashboard-section/DashboardSection";
import TextField from "../../../components/text-fields/TextField";
import DashboardSubSection from "../../../components/sections/dashboard-sub-section/DashboardSubSection";
import DashboardTextWrapper from "../../../components/sections/dashbord-text-wrapper/DashboardTextWrapper";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import BasicInput from "../../../components/inputs/basic-input/BasicInput";
import StyledButton from "../../../components/buttons/StyledButtonComponent";
import { apiUrl } from "../../../config/urls";
import AddCircleOutlineTwoToneIcon from "@mui/icons-material/AddCircleOutlineTwoTone";
import AddCircleOutlinedIcon from "@mui/icons-material/AddCircleOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import AddButton from "../../../components/buttons/AddButton";
import DropDownSelect from "../../../components/inputs/dropdown/DropDownSelect";
import DatePickerComponent from "../../../components/date-picker/DekstopDatePicker";
import MuiDatePicker from "../../../components/date-picker/MuiDatePicker";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Box } from "@mui/system";
import UnStyledButton from "../../../components/buttons/UnStyledButtonComponent";
import Grid from "@mui/material/Grid";
import { useNavigate } from "react-router-dom";
import { MERCHANT_DASHBOARD_RESTAURANT_DETAILS } from "../../../config/routes";
import StyledLink from "../../../components/links/styled-link/StyledLink";
import BreadCrumbs from "../../../components/breadcrumbs/BreadCrumbs";
import { useTranslation } from "react-i18next";
const axios = require("axios");

const MerchantRestaurantEditPage = ({ }) => {
	const { user } = useAuth();
	const theme = useTheme();
	const params = useParams();
	const location = useLocation();
	const navigate = useNavigate();
	const { t, i18n } = useTranslation();
	const [listingForm, setListingForm] = useState({});
	const [ownerNames, setOwnerNames] = useState(
		location.state?.ownerName.length > 0 ? location.state?.ownerName : [" "]
	);
	const [chefNames, setChefNames] = useState(
		location.state?.chefName.length > 0 ? location.state?.chefName : [" "]
	);
	const [bartenderNames, setBartenderNames] = useState(
		location.state?.bartenderName.split(",")
	);

	useEffect(() => {
		if (location.state === undefined || location.state === null) {
			navigate("/merchant-dashboard/home");
		}
	}, []);

	//Object.entries(location?.state?.private.owners)
	const [specialMentions, setSpecialMentions] = useState([
		//["pocOwned", false, "POC owned"],
		[
			"blackOwned",
			location?.state?.private?.socialEquality?.blackOwned
				? location?.state?.private?.socialEquality?.blackOwned
				: false,
			t('ESTABLISHMENT_INFORMATION.SOCIAL_EQUALITY.OPTIONS.blackOwned'),
		],
		[
			"womenOwned",
			location?.state?.private?.socialEquality?.womenOwned,
			t('ESTABLISHMENT_INFORMATION.SOCIAL_EQUALITY.OPTIONS.womenOwned'),
		],
		[
			"lgbtqOwned",
			location?.state?.private?.socialEquality?.lgbtqOwned,
			t('ESTABLISHMENT_INFORMATION.SOCIAL_EQUALITY.OPTIONS.blackOwned'),
		],
		[
			"pocOwned",
			location?.state?.private?.socialEquality?.pocOwned,
			t('ESTABLISHMENT_INFORMATION.SOCIAL_EQUALITY.OPTIONS.lgbtqOwned'),
		],
		[
			"asianOwned",
			location?.state?.private?.socialEquality?.asianOwned,
			t('ESTABLISHMENT_INFORMATION.SOCIAL_EQUALITY.OPTIONS.pocOwned'),
		],
		[
			"indigenousOwned",
			location?.state?.private?.socialEquality?.indigenousOwned,
			t('ESTABLISHMENT_INFORMATION.SOCIAL_EQUALITY.OPTIONS.indigenousOwned'),
		],
		[
			"latinoOwned",
			location?.state?.private?.socialEquality?.latinoOwned,
			t('ESTABLISHMENT_INFORMATION.SOCIAL_EQUALITY.OPTIONS.latinoOwned'),
		],
	]);

	//	Object.entries(location?.state?.private.merchantType)

	const [merchantTypes, setMerchantTypes] = useState([
		[
			"restaurant",
			location?.state?.private?.merchantType.restaurant
				? location?.state?.private?.merchantType.restaurant
				: false,
			"Restaurant",
		],
		[
			"coffeeShop",
			location?.state?.private?.merchantType?.coffeeShop
				? location?.state?.private?.merchantType?.coffeeShop
				: false,
			"Coffee Shop",
		],
		[
			"bar",
			location?.state?.private?.merchantType?.bar
				? location?.state?.private?.merchantType?.bar
				: false,
			"Bar",
		],
		[
			"wineBar",
			location?.state?.private?.merchantType?.wineBar
				? location?.state?.private?.merchantType?.wineBar
				: false,
			"Wine Bar",
		],
		[
			"cocktailBar",
			location?.state?.private?.merchantType?.cocktailBar
				? location?.state?.private?.merchantType?.cocktailBar
				: false,
			"Cocktail Bar",
		],
		[
			"tearoom",
			location?.state?.private?.merchantType?.tearoom
				? location?.state?.private?.merchantType?.tearoom
				: false,
			"Tea Room",
		],
		[
			"brewery",
			location?.state?.private?.merchantType?.brewery
				? location?.state?.private?.merchantType?.brewery
				: false,
			"Brewery",
		],
		[
			"byob",
			location?.state?.private?.merchantType?.byob
				? location?.state?.private?.merchantType?.byob
				: false,
			"BYOB",
		],
		[
			"pastryShop",
			location?.state?.private?.merchantType?.pastryShop
				? location?.state?.private?.merchantType?.pastryShop
				: false,
			"Pastry Shop",
		],
		[
			"bakery",
			location?.state?.private?.merchantType?.bakery
				? location?.state?.private?.merchantType?.bakery
				: false,
			"Bakery",
		],
		[
			"grocery",
			location?.state?.private?.merchantType?.grocery
				? location?.state?.private?.merchantType?.grocery
				: false,
			"Grocery",
		],
		[
			"iceCreamParlor",
			location?.state?.private?.merchantType?.iceCreamParlor
				? location?.state?.private?.merchantType?.iceCreamParlor
				: false,
			"Ice Cream Parlor",
		],
		[
			"counter",
			location?.state?.private?.merchantType?.counter
				? location?.state?.private?.merchantType?.counter
				: false,
			"Counter (ready-to-eat-meals)",
		],
		[
			"chocolateShop",
			location?.state?.private?.merchantType?.chocolateShop
				? location?.state?.private?.merchantType?.chocolateShop
				: false,
			"Chocolate Shop",
		],
		[
			"butcher",
			location?.state?.private?.merchantType?.butcher
				? location?.state?.private?.merchantType?.butcher
				: false,
			"Butcher",
		],
		[
			"farm",
			location?.state?.private?.merchantType?.farm
				? location?.state?.private?.merchantType?.farm
				: false,
			"Farm",
		],
		[
			"sandwichShop",
			location?.state?.private?.merchantType?.sandwichShop
				? location?.state?.private?.merchantType?.sandwichShop
				: false,
			"Sandwich Shop",
		],
		[
			"wineMerchant",
			location?.state?.private?.merchantType?.wineMerchant
				? location?.state?.private?.merchantType?.wineMerchant
				: false,
			"Wine Merchant",
		],
	]);

	const [menuDynamism, setMenuDynamism] = useState([
		[
			"veryDynamic",
			location?.state?.menuDynamism?.veryDynamic
				? location?.state?.menuDynamism?.veryDynamic
				: false,
			"menu changes more than 4 times per year",
		],
		[
			"dynamic",
			location?.state?.menuDynamism?.dynamic
				? location?.state?.menuDynamism?.dynamic
				: false,
			"menu changes between 1 and 3 times per year",
		],
		[
			"notDynamicWithDailies",
			location?.state?.menuDynamism?.notDynamicWithDailies
				? location?.state?.menuDynamism?.notDynamicWithDailies
				: false,
			"fixed menu with daily specials",
		],
		[
			"notDynamic",
			location?.state?.menuDynamism?.notDynamic
				? location?.state?.menuDynamism?.notDynamic
				: false,
			"fixed menu",
		],
	]);
	const [foodTypes, setFoodTypes] = useState([
		[
			"argentine",
			location.state?.private?.food?.cuisineType?.argentine,
			"Argentinian",
		],
		["café", location?.state?.private?.food?.cuisineType?.café, "Cafe"],
		[
			"caribbean",
			location?.state?.private?.food?.cuisineType?.caribbean,
			"Caribbean",
		],
		[
			"chilean",
			location?.state?.private?.food?.cuisineType?.chilean,
			"Chilean",
		],

		[
			"chinese",
			location?.state?.private?.food?.cuisineType?.chinese,
			"Chinese",
		],
		[
			"colombian",
			location?.state?.private?.food?.cuisineType?.colombian,
			"Colombian",
		],

		[
			"dessert",
			location?.state?.private?.food?.cuisineType?.dessert,
			"Dessert",
		],
		[
			"egyptian",
			location?.state?.private?.food?.cuisineType?.egyptian,
			"Egyptian",
		],
		[
			"english",
			location?.state?.private?.food?.cuisineType?.english,
			"English",
		],
		[
			"european",
			location?.state?.private?.food?.cuisineType?.european,
			"European",
		],
		[
			"ethiopian",
			location?.state?.private?.food?.cuisineType?.ethiopian,
			"Ethiopian",
		],
		[
			"fastfood",
			location?.state?.private?.food?.cuisineType?.fastfood,
			"Fastfood",
		],
		["french", location?.state?.private?.food?.cuisineType?.french, "French"],
		["fusion", location?.state?.private?.food?.cuisineType?.fusion, "Fusion"],
		["german", location?.state?.private?.food?.cuisineType?.german, "German"],
		["greek", location?.state?.private?.food?.cuisineType?.greek, "Greek"],
		[
			"haitian",
			location?.state?.private?.food?.cuisineType?.haitian,
			"Haitian",
		],
		[
			"hawaiian",
			location?.state?.private?.food?.cuisineType?.hawaiian,
			"Hawaiian",
		],
		["indian", location?.state?.private?.food?.cuisineType?.indian, "Indian"],
		[
			"iranian",
			location?.state?.private?.food?.cuisineType?.iranian,
			"Iranian",
		],
		[
			"italian",
			location?.state?.private?.food?.cuisineType?.italian,
			"Italian",
		],
		[
			"jamaican",
			location?.state?.private?.food?.cuisineType?.jamaican,
			"Jamaican",
		],
		[
			"japanese",
			location?.state?.private?.food?.cuisineType?.japanese,
			"Japanese",
		],
		["jewish", location?.state?.private?.food?.cuisineType?.jewish, "Jewish"],
		["korean", location?.state?.private?.food?.cuisineType?.korean, "Korean"],
		[
			"lebanese",
			location?.state?.private?.food?.cuisineType?.lebanese,
			"Lebanese",
		],
		[
			"localMarket",
			location?.state?.private?.food?.cuisineType?.localMarket,
			"Local Market (farm to table)",
		],
		[
			"mediterranean",
			location?.state?.private?.food?.cuisineType?.mediterranean,
			"Mediterranean",
		],
		[
			"mexican",
			location?.state?.private?.food?.cuisineType?.mexican,
			"Mexican",
		],
		[
			"middleEastern",
			location?.state?.private?.food?.cuisineType?.middleEastern,
			"Middle Eastern",
		],
		[
			"moroccan",
			location?.state?.private?.food?.cuisineType?.moroccan,
			"Moroccan",
		],
		[
			"northAmerican",
			location?.state?.private?.food?.cuisineType?.northAmerican,
			"North American (American, Quebecois, Canadien)",
		],
		[
			"peruvian",
			location?.state?.private?.food?.cuisineType?.peruvian,
			"Peruvian",
		],
		[
			"portuguese",
			location?.state?.private?.food?.cuisineType?.portuguese,
			"Portuguese",
		],
		[
			"russian",
			location?.state?.private?.food?.cuisineType?.russian,
			"Russian",
		],
		[
			"seafood",

			location?.state?.private?.food?.cuisineType?.seafood ||
			location?.state?.private?.food?.cuisineType?.seaFood,
			"Sea Food",
		],
		[
			"southEastAsia",
			location?.state?.private?.food?.cuisineType?.southEastAsia,
			"South East Asian",
		],

		[
			"spanish",
			location?.state?.private?.food?.cuisineType?.spanish,
			"Spanish",
		],
		["swiss", location?.state?.private?.food?.cuisineType?.swiss, "Swiss"],
		["syrian", location?.state?.private?.food?.cuisineType?.syrian, "Syrian"],
		["thai", location?.state?.private?.food?.cuisineType?.thai, "Thai"],
		[
			"turkish",
			location?.state?.private?.food?.cuisineType?.turkish,
			"Turkish",
		],
		[
			"venezuelan",
			location?.state?.private?.food?.cuisineType?.venezuelan,
			"Venezuelan",
		],
		[
			"vietnamese",
			location?.state?.private?.food?.cuisineType?.vietnamese,
			"Vietnamese",
		],
	]);
	useEffect(() => {
		setListingForm({
			name: location?.state?.name,
			ownerName: location?.state?.ownerName,
			chefName: location?.state?.chefName,
			pastryChefName: location?.state?.pastryChefName,
			floorManagerName: location?.state?.floorManagerName,
			designerName: location?.state?.designerName,
			sommelierName: location?.state?.sommelierName,
			gmName: location?.state?.gmName,
			bartenderName: location?.state?.bartenderName,
			openingDate: location?.state?.openingDate,
			wifi: location?.state?.wifi,
			groups: location?.state?.groups,
			maxPersonPerGroup: location?.state?.maxPersonPerGroup,
			restaurantPrivatization: location?.state?.restaurantPrivatization,
			restaurantMenuLink: location?.state?.restaurantMenuLink,
			lunchTableDhote: location?.state?.lunchTableDhote,
			catering: location?.state?.catering,
			tastingMenu: location?.state?.tastingMenu,
			isAccessible: location?.state?.isAccessible,
			seatNumber: location?.state?.seatNumber,
			seatTerrasseNumber: location?.state?.seatTerrasseNumber,
			seatPrivateNumber: location?.state?.seatPrivateNumber,
			facebook: location?.state?.socialMedia?.facebook,
			instagram: location?.state?.socialMedia?.instagram,
			cashOnly: location?.state?.paymentTypes?.cashOnly,
			debit: location?.state?.paymentTypes?.debit,
			credit: location?.state?.paymentTypes?.credit,
			takeout: location?.state?.private?.service?.takeout,
			menuDynamism: location?.state?.menuDynamism,
			private: {
				owners: location?.state?.private?.owners,
				merchantType: location?.state?.private?.merchantType,
				food: {
					cuisineType: location?.state?.private?.food?.cuisineType,
				},
				socialEquality: location?.state?.private?.socialEquality,
			},
		});

	}, []);

	const addInput = (type) => {
		let newfield = "";
		type === "owner"
			? setOwnerNames([...ownerNames, newfield])
			: type === "chef"
				? setChefNames([...chefNames, newfield])
				: type === "bartender"
					? setBartenderNames([...bartenderNames, newfield])
					: (newfield = null);
	};

	const deleteInput = (index, type) => {
		let newInputs;
		switch (type) {
			case "owner":
				newInputs = [...ownerNames];
				newInputs.splice(index, 1);
				setOwnerNames(newInputs);
				break;
			case "chef":
				newInputs = [...chefNames];
				newInputs.splice(index, 1);
				setChefNames(newInputs);
				break;
			case "bartender":
				newInputs = [...bartenderNames];
				newInputs.splice(index, 1);
				setBartenderNames(newInputs);
				break;
		}
	};

	const handleArrayChange = (index, event, type) => {
		let list;
		type === "owner"
			? (list = [...ownerNames])
			: type === "chef"
				? (list = [...chefNames])
				: type === "bartender"
					? (list = [...chefNames])
					: (list = null);
		list[index] = event.target.value;
		type === "owner"
			? setOwnerNames(list)
			: type === "chef"
				? setChefNames(list)
				: type === "bartender"
					? setBartenderNames(list)
					: (list = null);
	};

	const handleInputChange = (index, e, valueToChange) => {
		setListingForm((prevState) => ({
			...prevState,
			[valueToChange]: e.target.value,
		}));
		if (valueToChange === "maxPersonPerGroup" && e.target.value.length === 0) {
			setListingForm((prevState) => ({
				...prevState,
				groups: false,
			}));

		}
	};

	const handleDatePicker = (value, valueToChange) => {
		setListingForm((prevState) => ({
			...prevState,
			[valueToChange]: value,
		}));
	};

	const handleCheckBoxArray = (value, type, valueToChange) => {
		let update;
		if (
			type === "checked" &&
			valueToChange === "specialMentions" &&
			(value[1] === "no" ||
				value[1] === "N/A" ||
				value[1] === false ||
				value[1] === "false")
		) {
			update = [value[0], "yes", value[2]];
		} else if (
			type === "checked" &&
			valueToChange === "specialMentions" &&
			(value[1] === "yes" ||
				value[1] === "N/A" ||
				value[1] === true ||
				value[1] === "true")
		) {
			update = [value[0], "no", value[2]];
		} else if (
			type === "checked" &&
			valueToChange === "merchantType" &&
			(value[1] === "false" || value[1] === "N/A" || value[1] === false)
		) {
			update = [value[0], true, value[2]];
		} else if (
			type === "checked" &&
			valueToChange === "merchantType" &&
			(value[1] === "yes" || value[1] === "N/A" || value[1] === true)
		) {
			update = [value[0], false, value[2]];
		} else if (
			type === "checked" &&
			valueToChange === "foodType" &&
			(value[1] === "false" ||
				value[1] === "N/A" ||
				value[1] === false ||
				value[1] === undefined)
		) {
			update = [value[0], true, value[2]];
		} else if (
			type === "checked" &&
			valueToChange === "foodType" &&
			(value[1] === "yes" || value[1] === "N/A" || value[1] === true)
		) {
			update = [value[0], false, value[2]];
		} else if (type === "unchecked" && valueToChange === "specialMentions") {
			update = [value[0], "no", value[2]];
		} else if (type === "unchecked" && valueToChange === "merchantType") {
			update = [value[0], false, value[2]];
		} else if (type === "unchecked" && valueToChange === "foodType") {
			update = [value[0], false, value[2]];
		}
		if (valueToChange === "specialMentions") {
			setSpecialMentions((prevState) => {
				const newState = prevState.map((array, index) => {
					if (value === array) {
						return (array = update);
					} else {
						return array;
					}
				});
				return newState;
			});
		} else if (valueToChange === "merchantType") {
			setMerchantTypes((prevState) => {
				const newState = prevState.map((array, index) => {
					if (value === array) {
						return (array = update);
					} else {
						return array;
					}
				});
				return newState;
			});
		} else if (valueToChange === "foodType") {
			setFoodTypes((prevState) => {
				const newState = prevState.map((array, index) => {
					if (value === array) {
						return (array = update);
					} else {
						return array;
					}
				});
				return newState;
			});
		}
	};
	const handleAllowSingleValue = (value, type, valueToChange) => {
		let update;
		if (value[1] === false && type === "checked") {
			update = [value[0], true, value[2]];
		} else if (value[1] === true && type === "checked") {
			update = [value[0], false, value[2]];
		} else if (value[1] === true && type === "unchecked") {
			update = [value[0], false, value[2]];
		}

		setMenuDynamism((prevState) => {
			const newState = prevState.map((array, index) => {
				if (value === array) {
					return (array = update);
				} else if (value !== array) {
					const update = [...(array = [array[0], false, array[2]])];
					return update;
				}
			});
			return newState;
		});
	};

	const handleCheckBox = (e, valueToChange) => {
		let update;
		if (
			(valueToChange === "wifi" ||
				valueToChange === "groups" ||
				valueToChange === "tastingMenu" ||
				valueToChange === "isAccessible" ||
				valueToChange === "takeout" ||
				valueToChange === "catering") &&
			listingForm[valueToChange] === "yes"
		) {
			update = "no";
		} else if (
			(valueToChange === "wifi" ||
				valueToChange === "groups" ||
				valueToChange === "tastingMenu" ||
				valueToChange === "isAccessible" ||
				valueToChange === "takeout" ||
				valueToChange === "catering") &&
			(listingForm[valueToChange] === "no" ||
				listingForm[valueToChange] === "I don't know" ||
				listingForm[valueToChange] === "N/A")
		) {
			update = "yes";
		} else {
			update = e.target.checked;
		}
		setListingForm((prevState) => ({
			...prevState,
			[valueToChange]: update,
		}));

		if (valueToChange === "groups" && (update === "no" || update === false)) {
			setListingForm((prevState) => ({
				...prevState,
				maxPersonPerGroup: null,
			}));
		}
	};

	const handleMoneyBoxes = (e, valueToChange) => {
		if (
			valueToChange === "cashOnly" &&
			e.target.checked === true &&
			(listingForm.debit === true || listingForm.credit === true)
		) {
			setListingForm((prevState) => ({
				...prevState,
				cashOnly: e.target.checked,
				debit: false,
				credit: false,
			}));
		} else if (
			(valueToChange === "debit" || valueToChange === "credit") &&
			e.target.checked === true &&
			listingForm.cashOnly === true
		) {
			setListingForm((prevState) => ({
				...prevState,
				cashOnly: false,
				[valueToChange]: e.target.checked,
			}));
		} else {
			setListingForm((prevState) => ({
				...prevState,
				[valueToChange]: e.target.checked,
			}));
		}
	};

	const onSubmit = async (event) => {
		//event.preventDefault();

		const arrayToObject = (array) => {
			const reconstructedObject = Object.fromEntries(
				array.map((year, i) => {
					return year;
				})
			);
			return reconstructedObject;
		};

		const filterEmptyArrayValues = (arrayToFilter) => {
			const filteredArray = arrayToFilter.filter((value) => value.length !== 0);
			return filteredArray;
		};

		listingForm.ownerName = filterEmptyArrayValues(ownerNames);
		listingForm.chefName = filterEmptyArrayValues(chefNames);
		listingForm.bartenderName = bartenderNames.toString();
		if (listingForm.private !== undefined) {
			listingForm.private.socialEquality = arrayToObject(specialMentions);
		}
		listingForm.private.merchantType = arrayToObject(merchantTypes);

		listingForm.private.food.cuisineType = arrayToObject(foodTypes);

		listingForm.menuDynamism = arrayToObject(menuDynamism);

		const update = listingForm;

		await axios
			.put(`${apiUrl}/merchant/update-listing/${params.publicId}`, {
				listingForm,
				user: user.email
			})
			.then((res) => {
				navigate(
					`${MERCHANT_DASHBOARD_RESTAURANT_DETAILS}${location?.state?.publicId}`,
					{ state: res.data }
				);
			});
	};

	return (
		<>
			<BreadCrumbs href={`${MERCHANT_DASHBOARD_RESTAURANT_DETAILS}${params.publicId}`} state={location.state}>{t('BREADCRUMBS.BACK_TO')} {listingForm.name} {t('BREADCRUMBS.SUMMARY')}</BreadCrumbs>
			<TextField
				variant={"h1"}
				sx={{
					textAlign: "center",
				}}>
				{listingForm.name} {t('ESTABLISHMENT_INFORMATION.EDITING')}
			</TextField>


			<form onSubmit={(e) => onSubmit(e)}>
				<Paper sx={{ border: "solid 1px" }}>
					<StyledDashBoardSectionTitleContainer>
						<TextField
							variant={"h3"}
							sx={{
								textAlign: { xs: "center", md: "center", lg: "center" },
							}}>
							{t('ESTABLISHMENT_INFORMATION.TITLE')}
						</TextField>
					</StyledDashBoardSectionTitleContainer>

					<DashboardSection
						customStyle={{
							display: "flex",
							flexWrap: "wrap",
							mt: "10px",
							paddingBottom: "10px",
							borderBottom: "solid",
							borderColor: theme.palette.color_grey,
						}}>
						<DashboardSubSection
							customStyle={{ width: "100%", padding: "20px" }}>
							<TextField
								variant={"h4"}
								sx={{
									fontSize: " 1.4rem",
									textAlign: { xs: "center", md: "center", lg: "left" },
								}}>
								{t('ESTABLISHMENT_INFORMATION.STAFF_INFORMATION.TITLE')}
							</TextField>
							<Box sx={{ flexGrow: 1 }}>
								<Grid container spacing={3}>
									<Grid item xs={12} lg={4}>
										{ownerNames.map((owner, index) => (
											<React.Fragment key={index}>
												<Box
													sx={{
														display: "flex",
														alignItems: "flex-end",
														width: { xs: "100%", md: "100%", lg: "385px" },
													}}>
													<BasicInput
														//sx={{ width: { xs: "100%", md: "100%", lg: "300px" } }}
														label={t('ESTABLISHMENT_INFORMATION.STAFF_INFORMATION.OWNER')}
														value={owner}
														name={`${"ownerName_[" + index + "]"}`}
														index={index}
														valueToChange="owner"
														handleChange={handleArrayChange}></BasicInput>
													{index !== 0 && (
														<UnStyledButton
															type="button"
															onClick={() => deleteInput(index, "owner")}>
															X
														</UnStyledButton>
													)}
												</Box>
											</React.Fragment>
										))}

										<AddButton
											onClick={() => addInput("owner")}
											type="button"
											title="Add an owner"
										/>
									</Grid>
									<Grid item xs={12} lg={4}>
										{chefNames.map((chef, index) => (
											<Box
												key={index}
												sx={{
													display: "flex",
													alignItems: "flex-end",
													width: { xs: "100%", md: "100%", lg: "385px" },
												}}>
												<BasicInput
													label={t('ESTABLISHMENT_INFORMATION.STAFF_INFORMATION.CHEF')}
													value={chef}
													name={`${"ownerName_[" + index + "]"}`}
													index={index}
													valueToChange="chef"
													handleChange={handleArrayChange}></BasicInput>
												{index !== 0 && (
													<UnStyledButton
														type="button"
														onClick={() => deleteInput(index, "chef")}>
														X
													</UnStyledButton>
												)}
											</Box>
										))}
										<AddButton
											onClick={() => addInput("chef")}
											type="button"
											title="Add a Chef"
										/>
									</Grid>
									<Grid item xs={12} lg={4}>
										{bartenderNames?.map((chef, index) => (
											<Box
												key={index}
												sx={{
													display: "flex",
													alignItems: "flex-end",
													width: { xs: "100%", md: "100%", lg: "385px" },
												}}>
												<BasicInput
													label={t('ESTABLISHMENT_INFORMATION.STAFF_INFORMATION.BARTENDER')}
													value={chef}
													index={index}
													valueToChange="bartender"
													handleChange={handleArrayChange}></BasicInput>
												{index !== 0 && (
													<UnStyledButton
														type="button"
														onClick={() => deleteInput(index, "bartender")}>
														X
													</UnStyledButton>
												)}
											</Box>
										))}
									</Grid>

									<Grid item xs={12} lg={4}>
										<BasicInput
											label={t('ESTABLISHMENT_INFORMATION.STAFF_INFORMATION.SOMMELIER')}
											value={listingForm.sommelierName}
											valueToChange="sommelierName"
											handleChange={handleInputChange}></BasicInput>
									</Grid>
									<Grid item xs={12} lg={4}>
										<BasicInput
											label={t('ESTABLISHMENT_INFORMATION.STAFF_INFORMATION.PASTRY_CHEF')}
											value={listingForm.pastryChefName}
											valueToChange="pastryChefName"
											handleChange={handleInputChange}></BasicInput>
									</Grid>
									<Grid item xs={12} lg={4}>
										<BasicInput
											label={t('ESTABLISHMENT_INFORMATION.STAFF_INFORMATION.GENERAL_MANAGER')}
											value={listingForm.gmName}
											valueToChange="gmName"
											handleChange={handleInputChange}></BasicInput>
									</Grid>
									<Grid item xs={12} lg={4}>
										<BasicInput
											label={t('ESTABLISHMENT_INFORMATION.STAFF_INFORMATION.FLOOR_MANAGER')}
											value={listingForm.floorManagerName}
											valueToChange="floorManagerName"
											handleChange={handleInputChange}></BasicInput>
									</Grid>
									<Grid item xs={12} lg={4}>
										<BasicInput
											label={t('ESTABLISHMENT_INFORMATION.STAFF_INFORMATION.DESIGNER')}
											value={listingForm.designerName}
											valueToChange="designerName"
											handleChange={handleInputChange}></BasicInput>
									</Grid>
									<Grid item xs={12} lg={4}></Grid>
									<Grid item xs={12} lg={4}>
										<p>{t('ESTABLISHMENT_INFORMATION.SOCIAL_EQUALITY.FIRST_PARAGRAPH')}</p>
										{t('ESTABLISHMENT_INFORMATION.SOCIAL_EQUALITY.SECOND_PARAGRAPH')}
									</Grid>
									<Grid item xs={12} lg={4}>
										<DropDownSelect
											label={t('ESTABLISHMENT_INFORMATION.SOCIAL_EQUALITY.LABEL')}
											options={specialMentions}
											valueToChange="specialMentions"
											handleChange={handleCheckBoxArray}></DropDownSelect>
									</Grid>
								</Grid>
							</Box>
						</DashboardSubSection>
					</DashboardSection>
					<DashboardSection
						customStyle={{
							display: "flex",
							flexWrap: "wrap",
							mt: "10px",
							paddingBottom: "10px",
							borderBottom: "solid",
							borderColor: theme.palette.color_grey,

						}}>
						<DashboardSubSection
							customStyle={{ width: "100%", padding: "20px" }}>
							<TextField
								variant={"h4"}
								sx={{
									fontSize: " 1.4rem",
									textAlign: { xs: "center", md: "center", lg: "left" },

								}}>
								{t('OTHER_INFORMATION.TITLE')}
							</TextField>
							<Box sx={{ flexGrow: 1 }}>
								<Grid container spacing={3}>
									<Grid
										container
										spacing={1}
										xs={12}
										lg={8}
										paddingLeft={"24px"}
										paddingTop={"75px"}>
										<Grid item xs={12} lg={6} >
											<DropDownSelect
												label={t('OTHER_INFORMATION.LABELS.MERCHANT_TYPE')}
												options={merchantTypes}
												valueToChange="merchantType"
												handleChange={handleCheckBoxArray}></DropDownSelect>
										</Grid>
										<Grid item xs={12} lg={6}>
											<DropDownSelect
												label={t('OTHER_INFORMATION.LABELS.CUISINE_TYPE')}
												options={foodTypes}
												valueToChange="foodType"
												handleChange={handleCheckBoxArray}></DropDownSelect>
										</Grid>
										<Grid
											item
											xs={12} lg={6}
											sx={{
												display: "flex",
												flexDirection: "row",
												alignItems: "flex-end",
											}}>
											<DropDownSelect
												label={t('OTHER_INFORMATION.LABELS.MENU_DYNAMISM')}
												options={menuDynamism}
												valueToChange="menuDynamism"
												handleChange={handleAllowSingleValue}></DropDownSelect>
										</Grid>
										<Grid
											item
											xs={12} lg={6}
											sx={{
												marginTop: "60px",
												display: "flex",
												flexDirection: "row",
												alignItems: "flex-start",
											}}>
											<MuiDatePicker
												value={listingForm?.openingDate}
												handleChange={handleDatePicker}
												valueToChange="openingDate"
												label={t('OTHER_INFORMATION.LABELS.OPENING_DATE')}
											></MuiDatePicker>
										</Grid>

										<Grid item xs={12} lg={6}>
											<BasicInput

												label={t('OTHER_INFORMATION.LABELS.INTERIOR_SEATING')}
												type="number"
												value={listingForm.seatNumber}
												valueToChange="seatNumber"
												handleChange={handleInputChange}></BasicInput>
										</Grid>
										<Grid item xs={12} lg={6}>
											<BasicInput
												label="Facebook"
												value={listingForm.facebook}
												valueToChange="facebook"
												handleChange={handleInputChange}></BasicInput>
										</Grid>
										<Grid item xs={12} lg={6}>
											<BasicInput

												label={t('OTHER_INFORMATION.LABELS.TERRASSE_SEATING')}
												type="number"
												value={listingForm.seatTerrasseNumber}
												valueToChange="seatTerrasseNumber"
												handleChange={handleInputChange}></BasicInput>
										</Grid>
										<Grid item xs={12} lg={6}>
											<BasicInput
												label="Instagram"
												value={listingForm.instagram}
												valueToChange="instagram"
												handleChange={handleInputChange}></BasicInput>
										</Grid>
										<Grid item xs={12} lg={6}>
											<BasicInput
												label={t('OTHER_INFORMATION.LABELS.PRIVATE_ROOM_SEATING')}
												type="number"
												value={listingForm.seatPrivateNumber}
												valueToChange="seatPrivateNumber"
												handleChange={handleInputChange}></BasicInput>
										</Grid>
										<Grid item xs={12} lg={6}>
											<BasicInput

												label={t('OTHER_INFORMATION.LABELS.MENU_LINK')}
												value={listingForm.restaurantMenuLink}
												valueToChange="restaurantMenuLink"
												handleChange={handleInputChange}></BasicInput>
										</Grid>
										<Grid item xs={12} lg={6}></Grid>
									</Grid>
									<Grid
										container
										spacing={1}
										xs={12} lg={4}
										// xs={4}
										paddingLeft={"24px"}
										paddingTop={"75px"}>
										<Grid item xs={6}>
											<FormControlLabel
												control={
													<Checkbox
														onChange={(e) => handleCheckBox(e, "tastingMenu")}
														checked={
															listingForm?.tastingMenu === "yes" || listingForm?.tastingMenu === true || listingForm?.tastingMenu === "true" ? true : false
														}></Checkbox>
												}
												label={t('OTHER_INFORMATION.LABELS.TASTING_MENU')}
												labelPlacement="end"
											/>
										</Grid>
										<Grid item xs={6}>
											<FormControlLabel
												control={
													<Checkbox
														onChange={(e) => handleCheckBox(e, "catering")}
														checked={
															listingForm?.catering === "yes" || listingForm?.catering === true || listingForm?.catering === "true" ? true : false
														}></Checkbox>
												}
												label={t('OTHER_INFORMATION.LABELS.CATERING')}
												labelPlacement="end"
											/>
										</Grid>
										<Grid item xs={6}>
											<FormControlLabel
												control={
													<Checkbox
														onChange={(e) => handleCheckBox(e, "takeout")}
														checked={
															listingForm?.takeout === "yes" || listingForm?.takeout === true || listingForm?.takeout === "true" ? true : false
														}></Checkbox>
												}
												label={t('OTHER_INFORMATION.LABELS.OFFERS_TAKEOUT')}
												labelPlacement="end"
											/>
										</Grid>
										<Grid item xs={6}>
											<FormControlLabel
												control={
													<Checkbox
														onChange={(e) => handleCheckBox(e, "lunchTableDhote")}
														checked={
															listingForm?.lunchTableDhote === "yes" || listingForm?.lunchTableDhote === true || listingForm?.lunchTableDhote === "true" ? true : false
														}></Checkbox>
												}
												label="Lunch table d'hôte"
												labelPlacement="end"
											/>
										</Grid>


										<Grid item xs={6}>
											<FormControlLabel
												control={
													<Checkbox
														onChange={(e) => handleCheckBox(e, "groups")}
														checked={
															listingForm?.groups === "yes" || listingForm?.groups === true || listingForm?.groups === "true" ? true : false
														}></Checkbox>
												}
												label={t('OTHER_INFORMATION.LABELS.GROUP_FRIENDLY')}
												labelPlacement="end"
											/>
										</Grid>
										<Grid item xs={6}>
											<FormControlLabel
												control={
													<Checkbox
														onChange={(e) => handleMoneyBoxes(e, "cashOnly")}
														checked={listingForm?.cashOnly === true}
														inputProps={{
															"aria-label": "controlled",
														}}></Checkbox>
												}
												label={t('OTHER_INFORMATION.LABELS.cashOnly')}
												labelPlacement="end"
											/>
										</Grid>
										{listingForm?.groups === true || listingForm?.groups === 'yes' || listingForm?.groups === 'true' ? <Grid item xs={6}>
											<BasicInput
												sx={{ width: "inherit", mt: '0px' }}
												label={t('OTHER_INFORMATION.LABELS.MAX_PPL_GROUP')}
												type="number"
												value={listingForm.maxPersonPerGroup}
												valueToChange="maxPersonPerGroup"
												handleChange={handleInputChange}></BasicInput>
										</Grid> : <Grid item xs={6}>

										</Grid>}

										<Grid item xs={6}>
											<FormControlLabel
												control={
													<Checkbox
														onChange={(e) => handleMoneyBoxes(e, "debit")}
														checked={listingForm?.debit === true}></Checkbox>
												}
												label={t('OTHER_INFORMATION.LABELS.debit')}
												labelPlacement="end"
											/>
										</Grid>
										<Grid item xs={6}>
											<FormControlLabel
												control={
													<Checkbox
														onChange={(e) => handleCheckBox(e, "restaurantPrivatization")}
														checked={
															listingForm?.restaurantPrivatization === "yes" || listingForm?.restaurantPrivatization === true || listingForm?.restaurantPrivatization === "true" ? true : false
														}></Checkbox>
												}
												label={t('OTHER_INFORMATION.LABELS.RESTO_PRIVATIZATION')}
												labelPlacement="end"
											/>
										</Grid>

										<Grid item xs={6}>
											<FormControlLabel
												control={
													<Checkbox
														onChange={(e) => handleMoneyBoxes(e, "credit")}
														checked={listingForm?.credit === true}></Checkbox>
												}
												label={t('OTHER_INFORMATION.LABELS.credit')}
												labelPlacement="end"
											/>
										</Grid>

										<Grid item xs={6}>
											<FormControlLabel
												control={
													<Checkbox
														onChange={(e) => handleCheckBox(e, "wifi")}
														checked={listingForm?.wifi === "yes" || listingForm?.wifi === true || listingForm?.wifi === "true" ? true : false}
														inputProps={{
															"aria-label": "controlled",
														}}></Checkbox>
												}
												label="WIFI"
												labelPlacement="end"
											/>
										</Grid>

										<Grid item xs={6}>
											<FormControlLabel
												control={
													<Checkbox
														onChange={(e) => handleCheckBox(e, "isAccessible")}
														checked={
															listingForm?.isAccessible === "yes" || listingForm?.isAccessible === true || listingForm?.isAccessible === "true" ? true : false
														}></Checkbox>
												}
												label={t('OTHER_INFORMATION.LABELS.ACCESSIBLE')}
												labelPlacement="end"
											/>
										</Grid>
									</Grid>
								</Grid>
							</Box>
						</DashboardSubSection>
					</DashboardSection>
				</Paper>
				<Box
					sx={{
						display: "flex",
						justifyContent: "center",
						margin: "50px 0px 0px",
						width: { xs: "100%", lg: "100%" }
					}}>
					<StyledButton
						sx={{ color: "black", backgroundColor: "#edff22", mb: "20px" }}
						fullWidth={true}
						type="submit">
						{t('BUTTONS.SAVE_CHANGES')}
					</StyledButton>
				</Box>
			</form>
		</>
	);
};
export default MerchantRestaurantEditPage;

/**
 * 
 * 	<DropDownSelect
										label="Special mentions"
										options={specialMentions}
										valueToChange="specialMentions"
										handleChange={handleCheckBoxArray}></DropDownSelect>
 * 
 * 
 * 
 * 
 * 
 * 
 * <Grid item xs={4}>
									<BasicInput
										label="Bartender"
										value={listingForm.bartenderName}
										valueToChange="bartenderName"
										handleChange={handleInputChange}></BasicInput>
								</Grid>
 * 
 * 
 * 
 */
