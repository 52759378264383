import TextField from "../../../../components/text-fields/TextField";
import StyledLink from "../../../../components/links/styled-link/StyledLink";
import StyledInput from "../../../../components/inputs/styled-input/StyledInput";

import DashboardSection from "../../../../components/sections/dashboard-section/DashboardSection";
import DropDownSearchInput from "../../../../components/inputs/dropdown/DropDownSearchInput";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import StyledButton from "../../../../components/buttons/StyledButtonComponent";
import AccordionComponent from "../../../../components/accordion/AccordionComponent";
import DropDownMenu from "../../../../components/drop-down-menu/DropDownMenuComponent";
import Box from "@mui/material/Box";
import SortIcon from "@mui/icons-material/Sort";
import Divider from "@mui/material/Divider";
import ListComponent from "../../../../components/list/ListComponent";
import ListItemComponent from "../../../../components/list-item/ListItemComponent";
import FilterAccordion from "../../../../components/accordion/FilterAccordionComponent";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { useTheme } from "@mui/material";

const AdminListDetailsPage = ({}) => {
	const theme = useTheme();
	const categoryFilters = [
		{ category: "all" },
		{ category: "restaurant" },
		{ category: "cafe" },
		{ category: "bar" },
		{ category: "bakery" },
		{ category: "high-end" },
		{ category: "byob" },
		{ category: "wine-bar" },
	];

	const regionFilters = [
		{ category: "Old-Port" },
		{ category: "Outremont" },
		{ category: "Plateau" },
	];

	const cuisineFilters = [
		{ category: "Old-Port" },
		{ category: "Outremont" },
		{ category: "Plateau" },
	];

	const statusFilters = [
		{ category: "Old-Port" },
		{ category: "Outremont" },
		{ category: "Plateau" },
	];

	const filterByWrapper = {
		position: "relative",
		display: "flex",
		alignItems: "center",
		flexWrap: "wrap",
		borderRadius: "5px",
	};
	const buttonWrapperStyle = {
		display: "flex",
		width: "100%",
		mt: "20px",
		justifyContent: "center",
	};

	const dummyData = [
		{
			displayName: "Chez Croquette",
			address: "longue addresse pour tester un nom super long",
			merchantType: "restaurant",
			city: "Montreal",
			status: "open",
		},
		{
			displayName: "Bon Matin",
			address: "longue addresse pour tester un nom super long",
			merchantType: "restaurant",
			city: "Montreal",
			status: "open",
		},
		{
			displayName: "Le petit chat gris",
			address: "longue addresse pour tester un nom super long",
			merchantType: "restaurant",
			city: "Montreal",
			status: "closed",
		},
	];
	return (
		<>
			<DashboardSection
				customStyle={{ display: "flex", flexDirection: "column" }}>
				<TextField variant={"h1"}>Title of list </TextField>
				<TextField variant={"p"}>French : Best café in town </TextField>
				<TextField variant={"p"}>English : Best café in town</TextField>
				<TextField variant={"h1"}>Criterias </TextField>
				<TextField variant={"p"}>
					Selected: Lorem Ipsum is simply dummy text of the printing and
					typesetting industry. Lorem Ipsum has been the industry's standard
					dummy text ever since the 1500s, when an unknown printer took a galley
					of type and scrambled it to make a type specimen book.
				</TextField>
				<StyledLink href="/admin-dashboard/list/edit/:listId">
					Edit List
				</StyledLink>
			</DashboardSection>
			<Box sx={{ ...filterByWrapper }}>
				<FilterAccordion customStyle={{}}>
					<AccordionSummary
						aria-controls="panel1a-content"
						id="panel1a-header"
						expandIcon={<ExpandMoreIcon />}>
						<FilterAltOutlinedIcon />
						<TextField>Filter by:</TextField>
						<TextField>REGION CUISINE STATUS</TextField>
					</AccordionSummary>
					<AccordionDetails>
						<Box>
							<TextField>REGION</TextField>
							{regionFilters.map((subfilter) => (
								<FormControlLabel
									control={<Checkbox defaultChecked />}
									label={subfilter.category}
								/>
							))}
						</Box>
						<Box>
							<TextField>CUISINE</TextField>
							{cuisineFilters.map((subfilter) => (
								<FormControlLabel
									control={<Checkbox defaultChecked />}
									label={subfilter.category}
								/>
							))}
						</Box>
						<Box>
							<TextField>STATUS</TextField>
							{statusFilters.map((subfilter) => (
								<FormControlLabel
									control={<Checkbox defaultChecked />}
									label={subfilter.category}
								/>
							))}
						</Box>
						<TextField>
							Lorem ipsum dolor sit amet, consectetur adipiscing elit.
							Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
							eget.
						</TextField>
					</AccordionDetails>
				</FilterAccordion>

				<FilterAccordion customStyle={{}}>
					<AccordionSummary
						aria-controls="panel1a-content"
						id="panel1a-header"
						expandIcon={<ExpandMoreIcon />}>
						<SortIcon />
						<TextField
							variant={"h6"}
							sx={{ fontWeight: "900", fontSize: "1.25rem" }}>
							Sort By:
						</TextField>
					</AccordionSummary>
					<AccordionDetails>
						<TextField>
							Lorem ipsum dolor sit amet, consectetur adipiscing elit.
							Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
							eget.
						</TextField>
					</AccordionDetails>
				</FilterAccordion>
			</Box>
			<ListComponent
				tableHeaders={[
					"Name",
					"Address",
					"Merchant type",
					"Region",
					"Status",
					"Select",
				]}
				dummyData={dummyData}
				isVisible={true}
				darkMode={true}
				isAdmin={true}></ListComponent>

			<Box sx={{ ...buttonWrapperStyle }}>
				<StyledButton>SAVE CHANGES</StyledButton>
			</Box>
		</>
	);
};
export default AdminListDetailsPage;
