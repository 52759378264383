import TextField from "../../../../components/text-fields/TextField";
import StyledLink from "../../../../components/links/styled-link/StyledLink";
import StyledInput from "../../../../components/inputs/styled-input/StyledInput";
import BasicInput from "../../../../components/inputs/basic-input/BasicInput";

import DashboardSection from "../../../../components/sections/dashboard-section/DashboardSection";
import DropDownSearchInput from "../../../../components/inputs/dropdown/DropDownSearchInput";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import StyledButton from "../../../../components/buttons/StyledButtonComponent";
import AccordionComponent from "../../../../components/accordion/AccordionComponent";
import DropDownMenu from "../../../../components/drop-down-menu/DropDownMenuComponent";
import Box from "@mui/material/Box";
import SortIcon from "@mui/icons-material/Sort";
import Divider from "@mui/material/Divider";
import ListComponent from "../../../../components/list/ListComponent";
import ListItemComponent from "../../../../components/list-item/ListItemComponent";
import FilterAccordion from "../../../../components/accordion/FilterAccordionComponent";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { useTheme } from "@mui/material";

const AdminListCreatePage = ({}) => {
	const theme = useTheme();
	const categoryFilters = [
		{ category: "all" },
		{ category: "restaurant" },
		{ category: "cafe" },
		{ category: "bar" },
		{ category: "bakery" },
		{ category: "high-end" },
		{ category: "byob" },
		{ category: "wine-bar" },
	];

	const regionFilters = [
		{ category: "Old-Port" },
		{ category: "Outremont" },
		{ category: "Plateau" },
	];

	const cuisineFilters = [
		{ category: "Old-Port" },
		{ category: "Outremont" },
		{ category: "Plateau" },
	];

	const statusFilters = [
		{ category: "Old-Port" },
		{ category: "Outremont" },
		{ category: "Plateau" },
	];

	const filterByWrapper = {
		position: "relative",
		display: "flex",
		alignItems: "center",
		flexWrap: "wrap",
		borderRadius: "5px",
	};
	const buttonWrapperStyle = {
		display: "flex",
		width: "100%",
		mt: "20px",
		justifyContent: "center",
	};

	const dummyData = [
		{
			displayName: "Chez Croquette",
			address: "longue addresse pour tester un nom super long",
			merchantType: "restaurant",
			city: "Montreal",
			status: "open",
		},
		{
			displayName: "Bon Matin",
			address: "longue addresse pour tester un nom super long",
			merchantType: "restaurant",
			city: "Montreal",
			status: "open",
		},
		{
			displayName: "Le petit chat gris",
			address: "longue addresse pour tester un nom super long",
			merchantType: "restaurant",
			city: "Montreal",
			status: "closed",
		},
	];
	const criteriaFilters = [
		{ category: "Region" },
		{ category: "Day" },
		{ category: "Hours" },
		{ category: "Merchant type" },
		{ category: "cuisine" },
	];

	const customAccordionStyle = {
		backgroundColor: theme.palette.color_yellow,
	};
	return (
		<>
			<DashboardSection
				customStyle={{ display: "flex", flexDirection: "column" }}>
				<TextField variant={"h1"}>Create Listing</TextField>
				<BasicInput label="titre français"></BasicInput>
				<BasicInput label="english title"></BasicInput>

				<TextField variant={"h1"}>Criterias </TextField>
				<Box>
					{criteriaFilters.map((subfilter) => (
						<>
							<FormControlLabel
								control={<Checkbox />}
								label={subfilter.category}
							/>
							<BasicInput label={subfilter.category}></BasicInput>
						</>
					))}
				</Box>
				<StyledLink href="/admin-dashboard/list/edit/:listId">
					Edit List
				</StyledLink>
			</DashboardSection>
			<Box sx={{ ...filterByWrapper }}>
				<FilterAccordion customStyle={customAccordionStyle}>
					<AccordionSummary
						aria-controls="panel1a-content"
						id="panel1a-header"
						expandIcon={<ExpandMoreIcon />}>
						<TextField
							variant={"h6"}
							sx={{ fontWeight: "900", fontSize: "1.25rem" }}>
							ADDRESS INFORMATION
						</TextField>
					</AccordionSummary>
					<AccordionDetails>
						<Box>
							<TextField>REGION</TextField>
							{regionFilters.map((subfilter) => (
								<FormControlLabel
									control={<Checkbox defaultChecked />}
									label={subfilter.category}
								/>
							))}
						</Box>
						<Box>
							<TextField>CUISINE</TextField>
							{cuisineFilters.map((subfilter) => (
								<FormControlLabel
									control={<Checkbox defaultChecked />}
									label={subfilter.category}
								/>
							))}
						</Box>
						<Box>
							<TextField>STATUS</TextField>
							{statusFilters.map((subfilter) => (
								<FormControlLabel
									control={<Checkbox defaultChecked />}
									label={subfilter.category}
								/>
							))}
						</Box>
						<TextField>
							Lorem ipsum dolor sit amet, consectetur adipiscing elit.
							Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
							eget.
						</TextField>
					</AccordionDetails>
				</FilterAccordion>

				<FilterAccordion customStyle={customAccordionStyle}>
					<AccordionSummary
						aria-controls="panel1a-content"
						id="panel1a-header"
						expandIcon={<ExpandMoreIcon />}>
						<TextField
							variant={"h6"}
							sx={{ fontWeight: "900", fontSize: "1.25rem" }}>
							FOOD AND ALCOHOL
						</TextField>
					</AccordionSummary>
					<AccordionDetails>
						<TextField>
							Lorem ipsum dolor sit amet, consectetur adipiscing elit.
							Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
							eget.
						</TextField>
					</AccordionDetails>
				</FilterAccordion>

				<FilterAccordion customStyle={customAccordionStyle}>
					<AccordionSummary
						aria-controls="panel1a-content"
						id="panel1a-header"
						expandIcon={<ExpandMoreIcon />}>
						<TextField
							variant={"h6"}
							sx={{ fontWeight: "900", fontSize: "1.25rem" }}>
							OWNER
						</TextField>
					</AccordionSummary>
					<AccordionDetails>
						<TextField>
							Lorem ipsum dolor sit amet, consectetur adipiscing elit.
							Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
							eget.
						</TextField>
					</AccordionDetails>
				</FilterAccordion>

				<FilterAccordion customStyle={customAccordionStyle}>
					<AccordionSummary
						aria-controls="panel1a-content"
						id="panel1a-header"
						expandIcon={<ExpandMoreIcon />}>
						<TextField
							variant={"h6"}
							sx={{ fontWeight: "900", fontSize: "1.25rem" }}>
							ATMOSPHERE
						</TextField>
					</AccordionSummary>
					<AccordionDetails>
						<TextField>
							Lorem ipsum dolor sit amet, consectetur adipiscing elit.
							Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
							eget.
						</TextField>
					</AccordionDetails>
				</FilterAccordion>

				<FilterAccordion customStyle={customAccordionStyle}>
					<AccordionSummary
						aria-controls="panel1a-content"
						id="panel1a-header"
						expandIcon={<ExpandMoreIcon />}>
						<TextField
							variant={"h6"}
							sx={{ fontWeight: "900", fontSize: "1.25rem" }}>
							SERVICE AND MORE
						</TextField>
					</AccordionSummary>
					<AccordionDetails>
						<TextField>
							Lorem ipsum dolor sit amet, consectetur adipiscing elit.
							Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
							eget.
						</TextField>
					</AccordionDetails>
				</FilterAccordion>
			</Box>

			<Box sx={{ ...buttonWrapperStyle }}>
				<StyledButton>SAVE CHANGES</StyledButton>
			</Box>
		</>
	);
};
export default AdminListCreatePage;
