import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
const BasicPageLayout = ({ children, customLayout }) => {
	const defaultPageLayout = {
		width: { xs: "100vw", md: "100vw", lg: "100vw", xl: "100%" },
		padding: "0px",
		minHeight: "100vh",
	};
	return <Box sx={{ ...defaultPageLayout, ...customLayout }}>{children}</Box>;
};
export default BasicPageLayout;
