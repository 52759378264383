import Box from "@mui/material/Box";
import InputBase from "@mui/material/InputBase";
import FormControl from "@mui/material/FormControl";
import SearchIcon from "@mui/icons-material/Search";

const StyledInput = ({ label }) => {
	const defaultStyle = {
		width: "100%",
		borderRadius: "50px",
		padding: "7px",
		backgroundColor: "#edff22",
		height: "50px",
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
		mt: { xs: "60px", md: "60px" },
	};
	return (
		<Box sx={{ ...defaultStyle }} fullWidth>
			<FormControl>
				<InputBase placeholder={label}></InputBase>
			</FormControl>
			<SearchIcon />
		</Box>
	);
};
export default StyledInput;
