import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
const DashBoardPageLayout = ({ children }) => {
	const defaultDashBoardPageLayout = {
		mt: "85px",
		padding: {
			xs: "20px 15px 20px 15px",
			md: "20px 15px 20px 15px",
			lg: "100px 15px 20px 150px",
		},
		backgroundColor: "#f6f2eb",
		minHeight: "100vh",
		width: "100%",
		position: "relative"
	};
	return <Box sx={{ ...defaultDashBoardPageLayout }}>{children}</Box>;
};
export default DashBoardPageLayout;
