import Button from "@mui/material/Button";
import { useTheme } from "@mui/material/styles";
const UnStyledButton = ({ children, customStyle, onClick, type }) => {
	const theme = useTheme();
	const defaultUnstyledButtonStyle = {
		color: theme.palette.color_black,
	};

	return (
		<Button
			type={type}
			sx={{ ...defaultUnstyledButtonStyle, ...customStyle }}
			onClick={() => onClick()}>
			{children}
		</Button>
	);
};
export default UnStyledButton;
