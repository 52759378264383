export const muiThemes = {
	typography: {
		h1: {
			fontFamily: '"DM Sans", Open Sans',
			fontWeight: "900",
			fontSize: "3rem",
		},
		h2: {
			fontFamily: '"DM Sans", Open Sans',
			fontWeight: "900",
			fontSize: "2rem",
		},
		h3: {
			fontFamily: '"DM Sans", Open Sans',
			fontWeight: "900",
			fontSize: "1.6rem",
		},
		h4: {
			fontFamily: '"DM Sans", Open Sans',
			fontWeight: "900",
			fontSize: "0.875rem",
		},
		h5: {
			fontFamily: '"DM Sans", Open Sans',
			fontWeight: "900",
		},
		h6: {
			fontFamily: '"DM Sans", Open Sans',
			fontWeight: "900",
		},
		h7: {
			fontFamily: '"DM Sans", Open Sans',
			fontWeight: "900",
		},
		p: {
			fontFamily: '"DM Sans", Open Sans',
			fontSize: "1.375rem",
		},
	},
	palette: {
		color_white: "#ffffff",
		color_off_white: "#f9f9f7",
		color_beige: "#f6f2eb",
		color_grey: " #d4d4cd",
		color_green: "#182b18",
		color_yellow: "#edff22",
		color_black: "#000000",
		color_dark_grey: "#686e6f",
		color_fb: "#1877f2",
		color_error: "red",
		color_success: "#49a114",
		color_loader_1: "#acd02f",
		color_loader_2: "#75a131",
		color_loader_3: "#57832f",
		color_loader_4: "#3d642a",
	},

	font_sizes: {
		font_size_12: "1.2rem",
		font_size_13: "1.3rem",
		font_size_14: "0.875rem",
		font_size_16: "1.6rem",
		font_size_18: "1.8rem",
		font_size_20: "1.25rem",
		font_size_22: "2.2rem",
		font_size_24: "2.4rem",
		font_size_30: "3rem",
		font_size_37: "3.75rem",
		font_size_45: "4.5rem",
		font_size_80: "8rem",
		font_size_120: "12rem",
		font_size_175: "17.5rem",
		font_size_215: "21.5rem",
	},
};

/*color_white: #ffffff,
color_off_white: #f9f9f7,
color_beige: #f6f2eb,
color_grey: #d4d4cd,
color_green: #182b18,
color_yellow: #edff22,
color_black: #000000,
color_dark_grey: #686e6f,
color_fb: #1877f2,
color_error: red,
color_success: #49a114,
color_loader_1: #acd02f,
color_loader_2: #75a131,
color_loader_3: #57832f,
color_loader_4: #3d642a,
font_size_12: 1.2rem,
font_size_13: 1.3rem,
font-size-14: 0.875rem,
font_size_16: 1.6rem,
font_size_18: 1.8rem,
font-size-20: 1.25rem,
font_size_22: 2.2rem,
font_size_24: 2.4rem,
font_size_30: 3rem,
font_size_37: 3.75rem,
font_size_45: 4.5rem,
font_size_80: 8rem,
font_size_120: 12rem,
font_size_175: 17.5rem,
font_size_215: 21.5rem,
max_1140: 114rem,
max_916: 91.6rem,
max_685: 68.5rem,
max_620: 62rem,
max_565: 56.5rem,
max_520: 52rem,
max_480: 48rem,
max_430: 43rem,
max_400: 40rem,
max_370: 37rem,
max_350: 35rem,
max_320: 32rem,
max_290: 29rem,
max_280: 28rem,
max_250: 25rem,
max_242: 24.2rem,
max_220: 22rem,
max_214: 21.4rem,
max_200: 20rem,
max_160: 16rem,
max_150: 15rem,
max_141: 14.1rem,
max_130: 13rem,
max_120: 12rem,
max_110: 11rem,
max_115: 11.5rem,
max_98: 9.8rem,
max_80: 8rem,
max_75: 7.5rem,
max_72: 7.25rem,
max_60: 6rem,
max_50: 5rem,
max_48: 4.85rem,
max_45: 4.5rem,
max_40: 4rem,
max_30: 3rem,
max_25: 2.5rem,
max_22: 2.2rem,
max_20: 2rem,
max_15: 1.5rem,
max_12: 1.2rem,
max_10: 1rem,
max_7: 0.7rem,
max_6: 0.6rem,
max_5: 0.5rem,
max_2: 2px,
max_1: 1px,
line_height_1: 1em,
nav-height: 6.25rem,*/
