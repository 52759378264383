import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
	apiKey: "AIzaSyBLI81925LFYSR0lirWPe_gyw5HxeB2m0M",
	authDomain: "merchant-14ddd.firebaseapp.com",
	projectId: "merchant-14ddd",
	storageBucket: "merchant-14ddd.appspot.com",
	messagingSenderId: "631856026763",
	appId: "1:631856026763:web:a389d47f370ad42db3708e",
	measurementId: "G-4T4DLX2G1N",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const merchantDB = getFirestore(app);
