//GENERAL PAGES
export const HOMEPAGE = "/";
export const LOGIN = "/login";
export const ADDRESS_CLAIM_1 = "/address-claim/1";
export const ADDRESS_CLAIM_2 = "/address-claim/2";
export const ADDRESS_CLAIM_3 = "/address-claim/3";
export const PROFILE = "/profile";

//MERCHANT DASHBOARD PAGES
export const MERCHANT_DASHBOARD = "/merchant-dashboard";
export const MERCHANT_DASHBOARD_HOME_TAB = "/merchant-dashboard/home";
export const MERCHANT_DASHBOARD_COMMUNICATIONS_TAB =
	"/merchant-dashboard/communications";
export const MERCHANT_DASHBOARD_ADDADDRESS_TAB =
	"/merchant-dashboard/add-address";
export const MERCHANT_DASHBOARD_ADDADDRESS_SUCCESS =
	"/merchant-dashboard/add-address/success";
export const MERCHANT_DASHBOARD_RESTAURANT_DETAILS =
	"/merchant-dashboard/restaurant-details/";
export const MERCHANT_DASHBOARD_IMAGE_PURCHASE =
	"/merchant-dashboard/image-purchase";
export const MERCHANT_DASHBOARD_RESTAURANT_EDIT =
	"/merchant-dashboard/restaurant-edit/";

//ADMIN DASHBOARD PAGES

export const ADMIN_DASHBOARD = "/admin-dashboard";
export const ADMIN_DASHBOARD_SEARCH_TAB = "/admin-dashboard/search";
export const ADMIN_DASHBOARD_COMMUNITCATIONS_TAB =
	"/admin-dashboard/communitcations";
export const ADMIN_DASHBOARD_LIST_TAB = "/admin-dashboard/list";
export const ADMIN_DASHBOARD_LIST_DETAILS =
	"/admin-dashboard/list/details/:listId";
export const ADMIN_DASHBOARD_LIST_EDIT = "/admin-dashboard/list/edit/:listId";
export const ADMIN_DASHBOARD_LIST_CREATE = "/admin-dashboard/list/create";

export const ADMIN_DASHBOARD_RESTAURANT_DETAILS =
	"/admin-dashboard/restaurant-details/:publicId";
export const ADMIN_DASHBOARD_RESTAURANT_EDIT =
	"/admin-dashboard/restaurant-edit/:publicId";
