import { createContext, useContext, useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { apiUrl } from "../../config/urls";

import { getAuth } from "firebase/auth";
const axios = require("axios");

const MerchantListingContext = createContext(null);

const MerchantDataProvider = (props) => {
	const [publicMerchantListings, setPublicMerchantListings] = useState([]);
	const [listing, setListing] = useState();
	useEffect(() => {
		const auth = getAuth();
		const user = auth.currentUser;
		if (user) {
			axios
				.post(`${apiUrl}/merchant/public-listings`, user)
				.then((response) => {
					console.log('response', response.data)
					setPublicMerchantListings(response.data);
				});
		}
	}, []);
	// User is signed in, see docs for a list of available properties
	// https://firebase.google.com/docs/reference/js/firebase.User
	// ...

	return (
		<MerchantListingContext.Provider
			value={{ publicMerchantListings }}
			{...props}></MerchantListingContext.Provider>
	);
};

const useMerchantData = () => useContext(MerchantListingContext);
export { MerchantDataProvider, useMerchantData };
