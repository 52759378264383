import { createContext, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { auth, merchantDB } from "../../firebase";
import { updateProfile } from "firebase/auth";
import { doc, getDoc, getDocs } from "firebase/firestore";
import {
	reauthenticateWithCredential,
	updateEmail,
	EmailAuthProvider,
} from "firebase/auth";

import {
	sendSignInLinkToEmail,
	isSignInWithEmailLink,
	signInWithEmailLink,
	signOut,
	browserLocalPersistence,
} from "firebase/auth";
import {
	HOMEPAGE,
	MERCHANT_DASHBOARD_HOME_TAB,
	ADMIN_DASHBOARD_SEARCH_TAB,
} from "../../config/routes";
import BigLoader from "../../components/loaders/BigLoader";
import { apiUrl, appUrl } from "../../config/urls";
import i18next from "i18next";
import { useTranslation } from "react-i18next";

const axios = require("axios");

const defaultContext = {
	user: null,
	signUp: async () => ({ error: null, errors: null }),
	handleLogin: async () => ({ error: null, errors: null }),
	handleLogOut: () => console.log("not implemented"),
	setUsername: async (username) => ({ username, error: null, errors: null }),
	updateUser: () => console.log("not implemented"),
	open: false,
};

const AuthContext = createContext(defaultContext);
const AuthProvider = (props) => {
	const [email, setEmail] = useState("");
	const [user, setUser] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [isLoggingIn, setIsLoggingIn] = useState(false);
	const [open, setOpen] = useState(false);
	const [openAlertModal, setOpenAlertModal] = useState(false)
	const [error, setError] = useState("");
	const [inputError, setInputError] = useState(false)
	const [openUserProfile, setOpenUserProfile] = useState(false);
	const [userProfile, setUserProfile] = useState({
		first_name: "",
		last_name: "",
		phone: "",
		email: "",
	});
	const navigate = useNavigate();

	//https://tastet-data-6h5ndsgmdq-nn.a.run.app
	//http://localhost:3000/dashboard/overview

	const { t, i18n } = useTranslation();


	const actionCodeSettings = {
		url: appUrl,
		handleCodeInApp: true,
	};

	const submitProfileChange = async (e) => {
		e.preventDefault();
		e.stopPropagation();
		setIsLoading(true);
		const user = auth.currentUser;
		const email = userProfile.email;
		const oldEmail = auth.currentUser.email;

		await axios
			.post(`${apiUrl}/merchant-app/update-user`, {
				userProfile: userProfile,
				oldEmail: oldEmail,
				uid: auth.currentUser.uid,
			})
			.then((response) => {
				setIsLoading(false);
				if (response.data.error) {
					setOpenAlertModal(true)
					setInputError(true)
					setError('A user with that email already exists')
				}
				if (response.data === false) {
					setOpenUserProfile(false);
					setInputError(false)
				} else if (!response.data.error && response.data !== false) {
					setInputError(false)
					setOpenUserProfile(false);
					handleLogOut();
				}
			});
	};

	const signInWithEmail = async (email) => {
		if (email) {
			auth.languageCode = i18n.language
			await sendSignInLinkToEmail(auth, email, actionCodeSettings)
				.then(() => {
					window.localStorage.setItem("emailForSignIn", email);
				})
				.catch((error) => {
					console.log("err", error);
				});
		}
	};

	const handleLogin = async (e, email) => {
		e.preventDefault();
		setIsLoading(true);
		try {
			await axios
				.post(`${apiUrl}/merchant-app/login`, {
					email: email,
				})
				.then((response) => {
					if (response.data.error) {
						setIsLoading(false);
						setError(response.data.error.message);
						setOpenAlertModal(true);
						return;
					} else if (response.data.user) {
						setIsLoading(false);
						setEmail(response.data.user.email);
					}
					return response.data.user.email;
				})
				.then((email) => {
					signInWithEmail(email);
					setOpenAlertModal(true);
				});
		} catch (error) {
			console.error("error", error);
			setIsLoading(false);
			setError(error);
			setOpenAlertModal(true);
		}
	};

	const handleLogOut = async () => {
		signOut(auth)
			.then(() => {
				navigate(HOMEPAGE);
			})
			.catch((error) => {
				console.log("error", error);
				// An error happened.
			});
	};

	const userProfileSnap = async (docRef, email) => {
		const doc = await getDoc(docRef);
		setUserProfile(doc.data());
	};

	useEffect(() => {
		const handleFirebaseSignIn = async () => {
			try {
				setIsLoggingIn(true)
				const isSignIn = isSignInWithEmailLink(auth, window.location.href);
				if (isSignIn) {
					setIsLoading(true);
					let email = window.localStorage.getItem("emailForSignIn");
					if (!email) {
						setEmail(
							window.prompt("Please provide your email for confirmation")
						);
					}
					let isSigningIn = await signInWithEmailLink(auth, email, window.location.href)
						.then((result) => {
							window.localStorage.removeItem("emailForSignIn");
							auth
								.setPersistence(browserLocalPersistence)
								.then(() => {
									auth.currentUser
										.getIdTokenResult()
										.then((idTokenResult) => {
											setUser({ ...user, ...idTokenResult.claims });
											const docRef = doc(merchantDB, "merchants", user.user_id);
											userProfileSnap(docRef, user.email);



											if (
												idTokenResult.claims.admin ||
												idTokenResult.claims.editor
											) {
												navigate(ADMIN_DASHBOARD_SEARCH_TAB);
												setIsLoading(false);
												setIsLoggingIn(false)
											} else if (idTokenResult.claims.merchant) {
												navigate(MERCHANT_DASHBOARD_HOME_TAB);
												setIsLoading(false);
												setIsLoggingIn(false)
											} else {
												console.log("error");
											}
											return auth.currentUser.email
										})
										.catch((error) => {
											console.log("err", error);
										});
								})
								.catch((error) => {
									console.log("err", error);
								});
							return email
						})
						.catch((error) => {
							console.log("err", error);
						})

					sendSuccessLoginMessageToSlack(isSigningIn)
				} else {
					setIsLoggingIn(false)
				}
			} catch (err) {
				console.log("err", err);
			}
		};
		handleFirebaseSignIn();
	}, [navigate]);

	useEffect(() => {
		const unsubscribe = auth.onAuthStateChanged(async (user) => {
			setIsLoading(true);
			if (user) {
				auth.currentUser.getIdTokenResult().then((idTokenResult) => {
					setUser({ ...user, ...idTokenResult.claims });

					const docRef = doc(merchantDB, "merchants", user.uid);
					userProfileSnap(docRef, user.email);

					if (idTokenResult.claims.admin || idTokenResult.claims.editor) {
						navigate(ADMIN_DASHBOARD_SEARCH_TAB);
						setIsLoading(false);
					} else if (idTokenResult.claims.merchant) {
						navigate(MERCHANT_DASHBOARD_HOME_TAB);
						setIsLoading(false);
					}
				});
			} else {
				setUser(null);
				setIsLoading(false);
			}
		});
		return () => unsubscribe();
	}, []);

	const updateUserInformation = async () => {
		await updateProfile(auth.currentUser, {
			displayName: user.displayName,
			photoURL: "https://example.com/jane-q-user/profile.jpg",
		})
			.then(() => {

			})
			.catch((error) => {
				// An error occurred
				// ...
			});
	};

	const sendSuccessLoginMessageToSlack = async (email) => {
		try {
			await axios.post(`${apiUrl}/merchant-app/login-success`, {
				message: `user ${email} successfuly logged in the merchant app`
			}).then((res) => console.log(res))
		} catch (err) {
			console.log('err', err)
		}
	}

	if (isLoading || isLoggingIn) {
		return <BigLoader />
	};
	return (
		<AuthContext.Provider
			value={{
				setEmail,
				handleLogin,
				handleLogOut,
				setOpen,
				setError,
				user,
				email,
				isLoading,
				error,
				open,
				setUser,
				updateUserInformation,
				userProfile,
				setUserProfile,
				submitProfileChange,
				openUserProfile,
				setOpenUserProfile,
				openAlertModal,
				setOpenAlertModal,
				setInputError,
				inputError
			}}
			{...props}
		/>
	);
};

const useAuth = () => useContext(AuthContext);

export { AuthProvider, useAuth };
