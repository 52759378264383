import Box from "@mui/material/Box";

const BasicSection = ({ customStyle, children }) => {
	const defaultPageLayout = {
		//display: "flex",
		//	flexDirection: "column",
		//alignItems: "center",
		//	width: "100%",
	};
	return <Box sx={{ ...defaultPageLayout, ...customStyle }}>{children}</Box>;
};
export default BasicSection;
