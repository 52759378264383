import { Paper } from "@mui/material";
import StyledDashBoardSectionTitleContainer from "../../sections/styled-dashboard-section-title/StyledDashBoardSectionTitleContainer";
import DashboardSection from "../../sections/dashboard-section/DashboardSection";
import TextField from "../../text-fields/TextField";
import DashboardSubSection from "../../sections/dashboard-sub-section/DashboardSubSection";
import DashboardTextWrapper from "../../sections/dashbord-text-wrapper/DashboardTextWrapper";
import BasicInput from "../../inputs/basic-input/BasicInput";
import StyledButton from "../../buttons/StyledButtonComponent";
import AccordionComponent from "../../accordion/AccordionComponent";
import AccordionSummaryComponent from "../../accordion/AccordionSummaryComponent";
import AccordionDetailsComponent from "../../accordion/AccordionDetailsComponent";
import NotesForm from "../notes-form/NotesForm";
import { useTheme } from "@mui/material";

const AddressInformationForm = ({}) => {
	const theme = useTheme();
	return (
		<>
			{" "}
			<DashboardSection
				customStyle={{
					display: "flex",
					flexWrap: "wrap",
					mt: "10px",
					paddingBottom: "10px",
					borderBottom: "solid",
					borderColor: theme.palette.color_grey,
				}}>
				<DashboardSubSection customStyle={{ width: "100%" }}>
					<TextField
						variant={"h4"}
						sx={{
							textAlign: { xs: "center", md: "center", lg: "left" },
						}}>
						GENERAL INFORMATION
					</TextField>
					<BasicInput label="Name of establishment"></BasicInput>
					<BasicInput label="Name of contact"></BasicInput>
					<BasicInput label="Email address of contact"></BasicInput>
					<BasicInput label="Website"></BasicInput>
					<BasicInput label="Phone number"></BasicInput>
				</DashboardSubSection>
			</DashboardSection>
			<DashboardSection
				customStyle={{
					display: "flex",
					flexWrap: "wrap",
					mt: "10px",
					paddingBottom: "10px",
					borderBottom: "solid",
					borderColor: theme.palette.color_grey,
				}}>
				<DashboardSubSection customStyle={{ width: "100%" }}>
					<TextField
						variant={"h4"}
						sx={{
							textAlign: { xs: "center", md: "center", lg: "left" },
						}}>
						LOCATION
					</TextField>
					<BasicInput label="Street number"></BasicInput>
					<BasicInput label="Street address"></BasicInput>
					<BasicInput label="City"></BasicInput>
					<BasicInput label="State/Province"></BasicInput>
					<BasicInput label="Country/Region"></BasicInput>
					<BasicInput label="Postal Code"></BasicInput>
				</DashboardSubSection>
			</DashboardSection>
			<DashboardSection
				customStyle={{
					display: "flex",
					flexWrap: "wrap",
					mt: "10px",
					paddingBottom: "10px",
					borderBottom: "solid",
					borderColor: theme.palette.color_grey,
				}}>
				<DashboardSubSection customStyle={{ width: "100%" }}>
					<TextField
						variant={"h4"}
						sx={{
							textAlign: { xs: "center", md: "center", lg: "left" },
						}}>
						STAFF INFORMATION
					</TextField>
					<BasicInput label="Owner name"></BasicInput>
					<BasicInput label="Co-owner name"></BasicInput>
					<BasicInput label="Chef name"></BasicInput>
					<BasicInput label="Co-chef name"></BasicInput>
					<BasicInput label="Bartender name"></BasicInput>
					<BasicInput label="Sommelier name"></BasicInput>
					<BasicInput label="General manager name"></BasicInput>
				</DashboardSubSection>
			</DashboardSection>
			<DashboardSection
				customStyle={{
					display: "flex",
					flexWrap: "wrap",
					mt: "10px",
					paddingBottom: "10px",
					borderBottom: "solid",
					borderColor: theme.palette.color_grey,
				}}>
				<DashboardSubSection customStyle={{ width: "100%" }}>
					<TextField
						variant={"h4"}
						sx={{
							textAlign: { xs: "center", md: "center", lg: "left" },
						}}>
						OTHER INFORMATION
					</TextField>
					<BasicInput label="other information"></BasicInput>
					<BasicInput label="other information"></BasicInput>
					<BasicInput label="other information"></BasicInput>
					<BasicInput label="other information"></BasicInput>
					<BasicInput label="other information"></BasicInput>
				</DashboardSubSection>
			</DashboardSection>
		</>
	);
};
export default AddressInformationForm;
