import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import { useNavigate } from "react-router-dom";

const UnstyledLink = ({ href, children, label, customStyle }) => {
	const navigate = useNavigate();
	const defaultBoxStyle = {
		padding: "6px 8px",
		m: { xs: "5px 5px 5px 5px", md: "5px 5px 5px 5px" },
		cursor: "pointer",
	};

	const defaultLinkStyle = { color: "white" };

	const handleNavigation = () => {
		navigate(href);
	};

	return (
		<Box sx={{ ...defaultBoxStyle }}>
			<Link
				sx={{ ...defaultLinkStyle, ...customStyle }}
				onClick={() => handleNavigation()}
				underline="none">
				{children}
				<span>{label}</span>
			</Link>
		</Box>
	);
};
export default UnstyledLink;
