import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
const AccordionSummaryComponent = ({ customStyle, title }) => {
	return (
		<AccordionSummary
			expandIcon={<ExpandMoreIcon />}
			aria-controls="panel1a-content"
			id="panel1a-header"
			sx={{ ...customStyle }}>
			<Typography>{title}</Typography>
		</AccordionSummary>
	);
};
export default AccordionSummaryComponent;
