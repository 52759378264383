import { Navigate } from "react-router-dom";
import { useAuth } from "../../contexts/authContext/authContext";
import { Outlet } from "react-router-dom";
import TabsContainer from "../../components/dashboard/tabs-panel/TabPanelComponent";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import TocOutlinedIcon from "@mui/icons-material/TocOutlined";
import Box from "@mui/material/Box";
import {
	MERCHANT_DASHBOARD_HOME_TAB,
	MERCHANT_DASHBOARD_COMMUNICATIONS_TAB,
	MERCHANT_DASHBOARD_ADDADDRESS_TAB,
} from "../../config/routes";
import DashBoardPageLayout from "../../components/sections/dashboard-page-layout/DashBoardPageLayout";
import { MerchantDataProvider } from "../../contexts/authContext/merchantListingContext";
import BigLoader from "../../components/loaders/BigLoader";
import { useTranslation } from "react-i18next";
const MerchantDashBoardPage = ({ children }) => {
	const iconsStyle = { color: "white" };
	const { t, i18n } = useTranslation();
	const tabLinks = [
		{
			label: t('BUTTONS.HOME'),
			icon: <HomeOutlinedIcon style={iconsStyle} />,
			path: MERCHANT_DASHBOARD_HOME_TAB,
		},
		/*{
			label: "MESSAGES",
			icon: <EmailOutlinedIcon style={iconsStyle} c />,
			path: MERCHANT_DASHBOARD_COMMUNICATIONS_TAB,
		},
		{
			label: "ADD ADDRESS",
			icon: <TocOutlinedIcon style={iconsStyle} />,
			path: MERCHANT_DASHBOARD_ADDADDRESS_TAB,
		},*/
	];
	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: { xs: "column", md: "column", lg: "row" },
			}}>
			<TabsContainer tabs={tabLinks}></TabsContainer>
			<DashBoardPageLayout>
				<MerchantPrivateRoute>
					<MerchantDataProvider>
						{children} <Outlet />
					</MerchantDataProvider>
				</MerchantPrivateRoute>
			</DashBoardPageLayout>
		</Box>
	);
};

const MerchantPrivateRoute = ({ children }) => {
	const auth = useAuth();
	if (auth.user === null) {
		return <BigLoader />;
	} else

		if (
			auth?.user !== null &&
			(auth?.user?.merchant || auth?.user?.editor || auth?.user?.admin)
		) {
			return children;
		} else {
			return <Navigate to="/" />;
		}
};

export default MerchantDashBoardPage;
