import StyledButton from "../../../components/buttons/StyledButtonComponent";
import AccordionComponent from "../../../components/accordion/AccordionComponent";
import AccordionSummaryComponent from "../../../components/accordion/AccordionSummaryComponent";
import AccordionDetailsComponent from "../../../components/accordion/AccordionDetailsComponent";
import NotesForm from "../../../components/forms/notes-form/NotesForm";
import AddressInformationForm from "../../../components/forms/address-information-form/AddressInformationForm";
import AlcoholForm from "../../../components/forms/alcohol-form/AlcoholForm";
import AtmosphereForm from "../../../components/forms/atmosphere-form/AtmosphereForm";
import MerchantTypeAndCuisineForm from "../../../components/forms/merchant-type&cuisine-form/MerchantTypeAndCuisineForm";
import FoodForm from "../../../components/forms/food-form/FoodForm";
import OwnerForm from "../../../components/forms/owner-form/OwnerForm";
import PhotoForm from "../../../components/forms/photos-form/PhotoForm";
import ServiceAndMoreForm from "../../../components/forms/service&more-form/ServiceAndMoreForm";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material";

const AdminRestaurantEditPage = ({}) => {
	const theme = useTheme();
	const buttonWrapperStyle = {
		display: "flex",
		width: "100%",
		mt: "20px",
		justifyContent: "center",
	};
	const dummyData = [
		{
			displayName: "Chez Croquette",
			city: "Montreal",
			views: "10k",
			likes: "2k",
			bookMarks: "5k",
			id: "1234",
			address: "514 rue du Palais, Montreal, H2V 3B3",
			contact: "Jean Dujardins",
			website: "chez-croq.ca",
			phone: "123 456 789",
			email: "JeanD@chez-croq.ca",
			owners: "Thomas de Montigny, Élizabeth McGovern",
			chefs: "Alfonso Puy, Lionel Basta",
			general_manager: "Jean Dujardins",
			bartender: "Sophie Lacasse",
			sommelier: "Margaret Chesterman",
			hours: [
				"Monday: Closed",
				"Tuesday: Closed",
				"Wednesday: Closed",
				"Thursday: 5:30 – 10:00 PM",
				"Friday: 5:30 – 10:00 PM",
				"Saturday: 5:30 – 10:00 PM",
				"Sunday: Closed",
			],
			open_date: "june 24th 2012",
			facebook: "facebook/chez-croq.com",
			instagram: "@chez-croq",
			payement_type: "Visa, MasterCard, Interac",
			seats: "15",
			accessible: "no",
			reservation: "yes",
			private: "yes",
			wifi: "yes",
			tasting: "no",
			groups: "no",
			deliveries: "yes",
		},
	];
	return (
		<>
			<AccordionComponent>
				<AccordionSummaryComponent title={"PHOTOS"}></AccordionSummaryComponent>
				<AccordionDetailsComponent>
					<PhotoForm></PhotoForm>
				</AccordionDetailsComponent>
			</AccordionComponent>
			<AccordionComponent>
				<AccordionSummaryComponent title={"NOTES"}></AccordionSummaryComponent>
				<AccordionDetailsComponent>
					<NotesForm></NotesForm>
				</AccordionDetailsComponent>
			</AccordionComponent>
			<AccordionComponent>
				<AccordionSummaryComponent
					title={"CLIENT INFORMATION"}></AccordionSummaryComponent>
				<AccordionDetailsComponent>
					<AddressInformationForm></AddressInformationForm>
				</AccordionDetailsComponent>
			</AccordionComponent>
			<AccordionComponent>
				<AccordionSummaryComponent
					title={"MERCHANT TYPE & CUISINE"}></AccordionSummaryComponent>
				<AccordionDetailsComponent>
					<MerchantTypeAndCuisineForm></MerchantTypeAndCuisineForm>
				</AccordionDetailsComponent>{" "}
			</AccordionComponent>
			<AccordionComponent>
				<AccordionSummaryComponent title={"FOOD"}></AccordionSummaryComponent>
				<AccordionDetailsComponent>
					<FoodForm></FoodForm>
				</AccordionDetailsComponent>{" "}
			</AccordionComponent>
			<AccordionComponent>
				<AccordionSummaryComponent
					title={"ALCOHOL"}></AccordionSummaryComponent>
				<AccordionDetailsComponent>
					<AlcoholForm></AlcoholForm>
				</AccordionDetailsComponent>{" "}
			</AccordionComponent>
			<AccordionComponent>
				<AccordionSummaryComponent title={"OWNER"}></AccordionSummaryComponent>
				<AccordionDetailsComponent>
					<OwnerForm></OwnerForm>
				</AccordionDetailsComponent>{" "}
			</AccordionComponent>
			<AccordionComponent>
				<AccordionSummaryComponent
					title={"ATMOSPHERE"}></AccordionSummaryComponent>
				<AccordionDetailsComponent>
					<AtmosphereForm></AtmosphereForm>
				</AccordionDetailsComponent>{" "}
			</AccordionComponent>
			<AccordionComponent>
				<AccordionSummaryComponent
					title={"SERVICE & MORE"}></AccordionSummaryComponent>
				<AccordionDetailsComponent>
					<ServiceAndMoreForm></ServiceAndMoreForm>
				</AccordionDetailsComponent>{" "}
			</AccordionComponent>
			<Box sx={{ ...buttonWrapperStyle }}>
				<StyledButton>SAVE CHANGES</StyledButton>
			</Box>
		</>
	);
};
export default AdminRestaurantEditPage;
