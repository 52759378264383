import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

const BigLoader = ({}) => {
	return (
		<Box
			sx={{
				backgroundColor: "#ccc9c9",
				display: "flex",
				width: "100vw",
				height: "100vh",
				alignItems: "center",
				justifyContent: "center",
			}}>
			<CircularProgress />
		</Box>
	);
};
export default BigLoader;
