import { createRef, useState } from "react";
import Box from "@mui/material/Box";
import { InputLabel } from "@mui/material";
import InputBase from "@mui/material/InputBase";
import FormControl from "@mui/material/FormControl";

const LoginInput = ({
	required,
	label,
	sx,
	onChange,
	value,
	name,
	onChangeMap,
	index,
	handleChange,
	valueToChange,
	type,
}) => {
	const inputRef = createRef();
	const defaultStyle = {
		width: { xs: "100%", sm: '50%', md: "500px", lg: "500px" },
		borderRadius: "5px",
		padding: "7px",
		backgroundColor: "white",
		height: "50px",
		//	display: "flex",
		//justifyContent: "space-between",
		//alignItems: "center",
		border: "solid",
		borderWidth: "1px",
		mt: { xs: "60px", md: "60px" },
	};

	return (
		<Box sx={{ ...defaultStyle, ...sx }}>
			<FormControl required={required} sx={{ width: "100%" }}>
				<InputLabel htmlFor={label}>{label}</InputLabel>
				<InputBase
					required={required}
					value={value}
					name="search"
					type="text"
					autoComplete="email"
					id={value}
					sx={{ width: "100%" }}
					onChange={(e) => handleChange(e.target.value)}></InputBase>
			</FormControl>
		</Box>
	);
};
export default LoginInput;
