import Button from "@mui/material/Button";
import { useTheme } from "@mui/material/styles";
const RoundButton = ({ text }) => {
	const theme = useTheme();

	const defaultRoundButtonStyle = {
		borderRadius: "20px",
		backgroundColor: theme.palette.color_black,
		color: theme.palette.color_white,
		margin: "10px",
		width: "fit-content",
	};
	return <Button sx={{ ...defaultRoundButtonStyle }}>{text}</Button>;
};
export default RoundButton;
