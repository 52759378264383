import Typography from "@mui/material/Typography";

const TextField = ({ sx, variant, children }) => {
	return (
		<Typography sx={{ ...sx }} variant={variant}>
			{children}
		</Typography>
	);
};
export default TextField;
