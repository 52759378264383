import Button from "@mui/material/Button";
import { useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";

const StyledButton = ({
	sx,
	color,
	children,
	variant,
	fullWidth,
	navigateTo,
	handleClick,
	type,
}) => {
	const navigate = useNavigate();
	/*const handleClick = () => {
		console.log("navigateTo", navigateTo);
		navigate(navigateTo);
	};*/
	const theme = useTheme();
	const defaultButtonStyle = {
		backgroundColor: theme.palette.color_yellow,
		color: theme.palette.color_black,
		width: { xs: "100%", md: "100%", lg: "300px" },
	};

	return (
		<Button
			sx={{ ...defaultButtonStyle, ...sx }}
			color={color}
			variant={variant}
			fullWidth={fullWidth}
			onClick={handleClick}
			type={type}>
			{children}
		</Button>
	);
};
export default StyledButton;
