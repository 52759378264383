import { useMerchantData } from "../../../contexts/authContext/merchantListingContext";
import { useAuth } from "../../../contexts/authContext/authContext";
import TextField from "../../../components/text-fields/TextField";
import DashboardSection from "../../../components/sections/dashboard-section/DashboardSection";
import BasicSection from "../../../components/sections/basic-section/BasicSection";
import RestoCard from "../../../components/cards/RestoCardComponent";
import React from "react";
import { useTranslation } from "react-i18next";

const MerchantHomeTabPage = ({ }) => {
	const { publicMerchantListings } = useMerchantData();
	const { user, userProfile } = useAuth();
	const { t, i18n } = useTranslation();
	let displayName;

	if (userProfile?.first_name && userProfile?.last_name) {
		displayName = userProfile?.first_name.concat(" ", userProfile?.last_name);
	} else {
		displayName = user?.email;
	}

	return (
		<>
			<TextField variant={"h1"} sx={{ fontWeight: "900", fontSize: "2rem" }}>
				{t("MERCHANT_HOME_TAB_PAGE.HEADER.FIRST_TITLE")} {displayName}
			</TextField>
			<DashboardSection
				customStyle={{ width: { xs: "100%", md: "100%", lg: "1000px" } }}>
				<BasicSection
					customStyle={{
						display: "flex",
						flexWrap: "wrap",
						justifyContent: { lg: "space-between" },
					}}>
					<TextField
						variant={"h1"}
						sx={{ fontWeight: "900", fontSize: "2rem" }}>
						{t("MERCHANT_HOME_TAB_PAGE.HEADER.SECOND_TITLE")}
					</TextField>
				</BasicSection>
				<BasicSection
					customStyle={{
						display: "flex",
						flexWrap: "wrap",
						//	justifyContent: { lg: "space-between" },
					}}>
					{publicMerchantListings &&
						publicMerchantListings.map((restaurant) => (
							<React.Fragment key={restaurant._id}>
								<RestoCard restaurant={restaurant} view={t('BUTTONS.VIEW')} edit={t('BUTTONS.EDIT')}></RestoCard>
							</React.Fragment>
						))}
				</BasicSection>
			</DashboardSection>
		</>
	);
};
export default MerchantHomeTabPage;

/**
 * 	<StyledLink href="/merchant-dashboard/add-address">
						+ Claim a new address
					</StyledLink>
 * 
 * 
 * 
 * 
 * 	<DashboardSection
				customStyle={{ width: { xs: "100%", md: "100%", lg: "1000px" } }}>
				<TextField variant={"h1"} sx={{ fontWeight: "900", fontSize: "2rem" }}>
					Metrics
				</TextField>
				<TabsContainer
					tabs={dummyData}
					isMetrics={true}
					customStyle={{
						display: "flex",
						flexDirection: { xs: "row", md: "row", lg: "row" },
					}}></TabsContainer>

				<p>plein de metrics trop cools! w00t</p>
			</DashboardSection>
 * 
 */
