import * as React from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MobileStepper from "@mui/material/MobileStepper";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import SwipeableViews from "react-swipeable-views";
import FeatureCard from "../cards/FeatureCardComponent";

const AutoPlaySwipeableViews = SwipeableViews;

const FeatureCarousel = ({ features }) => {
	const theme = useTheme();
	const [activeStep, setActiveStep] = React.useState(0);
	const maxSteps = features.length;
	const desktopSteps = features.length;

	console.log("features", features);

	const handleNext = () => {
		setActiveStep((prevActiveStep) => prevActiveStep + 1);
	};

	const handleBack = () => {
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
	};

	const handleStepChange = (step) => {
		setActiveStep(step);
	};

	return (
		<>
			<Box
				sx={{
					maxWidth: { xs: 400, md: 400 },
					flexGrow: { xs: 1, md: 1 },
					display: { lg: "none" },
				}}>
				<AutoPlaySwipeableViews
					axis={theme.direction === "rtl" ? "x-reverse" : "x"}
					index={activeStep}
					onChangeIndex={handleStepChange}
					enableMouseEvents>
					{features.map((feature, index) => (
						<div key={feature.label}>
							{Math.abs(activeStep - index) <= 2 ? (
								<FeatureCard feature={feature} />
							) : null}
						</div>
					))}
				</AutoPlaySwipeableViews>
				<MobileStepper
					sx={{ backgroundColor: theme.palette.color_beige }}
					steps={maxSteps}
					position="static"
					activeStep={activeStep}
					nextButton={
						<Button
							size="small"
							onClick={handleNext}
							disabled={activeStep === maxSteps - 1}>
							Next
							{theme.direction === "rtl" ? (
								<KeyboardArrowLeft />
							) : (
								<KeyboardArrowRight />
							)}
						</Button>
					}
					backButton={
						<Button
							size="small"
							onClick={handleBack}
							disabled={activeStep === 0}>
							{theme.direction === "rtl" ? (
								<KeyboardArrowRight />
							) : (
								<KeyboardArrowLeft />
							)}
							Back
						</Button>
					}
				/>
			</Box>

			<Box
				sx={{
					maxWidth: { lg: "100%", lg: "100%" },
					flexGrow: { lg: 1, lg: 1 },
					display: { xs: "none", md: "none", lg: "block" },
				}}>
				<AutoPlaySwipeableViews
					axis={theme.direction === "rtl" ? "x-reverse" : "x"}
					index={activeStep}
					onChangeIndex={handleStepChange}
					enableMouseEvents>
					<Box sx={{ display: { lg: "flex", sx: "none", md: "none" } }}>
						{features.map((feature, index) => (
							<div key={feature.label}>
								{Math.abs(activeStep - index) <= 2 ? (
									<FeatureCard feature={feature} />
								) : null}
							</div>
						))}
					</Box>
				</AutoPlaySwipeableViews>
				<MobileStepper
					sx={{ backgroundColor: theme.palette.color_beige }}
					steps={desktopSteps}
					position="static"
					activeStep={activeStep}
					nextButton={
						<Button
							size="small"
							onClick={handleNext}
							disabled={activeStep === desktopSteps - 1}>
							Next
							{theme.direction === "rtl" ? (
								<KeyboardArrowLeft />
							) : (
								<KeyboardArrowRight />
							)}
						</Button>
					}
					backButton={
						<Button
							size="small"
							onClick={handleBack}
							disabled={activeStep === 0}>
							{theme.direction === "rtl" ? (
								<KeyboardArrowRight />
							) : (
								<KeyboardArrowLeft />
							)}
							Back
						</Button>
					}
				/>
			</Box>
		</>
	);
};

export default FeatureCarousel;
