import { useEffect, useState } from "react";
import React from "react";
import { useLocation } from "react-router-dom";
import { useMerchantData } from "../../../contexts/authContext/merchantListingContext";
import { useParams } from "react-router-dom";
import { useTheme } from "@mui/material";
import Carousel from "../../../components/carousel/CarouselComponent";
import Box from "@mui/material/Box";
import Label from "../../../components/text-fields/Label";
import TextField from "../../../components/text-fields/TextField";
import StyledLink from "../../../components/links/styled-link/StyledLink";
import DashboardSection from "../../../components/sections/dashboard-section/DashboardSection";
import StyledDashBoardSectionTitleContainer from "../../../components/sections/styled-dashboard-section-title/StyledDashBoardSectionTitleContainer";
import DashboardSubSection from "../../../components/sections/dashboard-sub-section/DashboardSubSection";
import DashboardTextWrapper from "../../../components/sections/dashbord-text-wrapper/DashboardTextWrapper";
import FeatureCarousel from "../../../components/carousel/FeatureCarouselComponent";
import StyledExternalLink from "../../../components/links/external-links/StyledExternalLink";
import BasicTooltip from "../../../components/tool-tip/ToolTip";
import StyledMailto from "../../../components/links/styled-mailto/StyledMailto";

import {
	MERCHANT_DASHBOARD_IMAGE_PURCHASE,
	MERCHANT_DASHBOARD_RESTAURANT_EDIT,
} from "../../../config/routes";

import { useTranslation } from "react-i18next";

const MerchantRestaurantDetailsPage = ({ }) => {
	const theme = useTheme();
	const location = useLocation();
	const { t, i18n } = useTranslation();
	const [listing, setListing] = useState();

	useEffect(() => {
		setListing(location?.state);
	}, []);


	// const featuresObjectToArray = Object.values(location?.state?.articles);
	// const features = featuresObjectToArray[0].concat(featuresObjectToArray[1]);

	// mastercard: ,
	//   visa: ,
	//   amex: ,
	//   cash: ,

	const objectKeyAsDisplayIfValueTrue = (object, type) => {
		if (object !== undefined) {
			const objectToArray = Object.entries(object);
			const filteredArray = objectToArray.filter(
				([key, value]) =>
					(value === true || value === "yes") &&
					key !== "small" &&
					key !== "friendly" &&
					key !== "mastercard" &&
					key !== "visa" &&
					key !== "amex" &&
					key !== "cash"
			);
			let value;
			return filteredArray.map((filteredValue, index) => {
				switch (type) {
					case "cuisineType":
						value = t(`OTHER_INFORMATION.CUISINE_TYPE_OPTIONS.${filteredValue[0]}`);
						break;
					case "socialEquality":
						value = t(`ESTABLISHMENT_INFORMATION.SOCIAL_EQUALITY.OPTIONS.${filteredValue[0]}`);
						break;
					case "paymentTypes":
						value = t(`OTHER_INFORMATION.LABELS.${filteredValue[0]}`);
						break;
					case "merchantType":
						value = t(`OTHER_INFORMATION.MERCHANT_TYPE_OPTIONS.${filteredValue[0]}`);
						break;
					case "menuDynamism":
						value = t(`OTHER_INFORMATION.MENU_DYNAMISM_OPTIONS.${filteredValue[0]}`);
						break;

					// default:
					// 	value = t(`OTHER_INFORMATION.CUISINE_TYPE_OPTIONS.${filteredValue[0]}`);
				}

				return (
					<TextField variant={"p"}>{(index ? ", " : "") + value}</TextField>
				);
			});
		}
	};

	let schedule;

	if (location?.state !== null) {
		schedule = Object.entries(location?.state?.schedules);
	}


	const locationHelperText = (
		<h1
			style={{
				color: "#000000",
				opacity: "1",
			}}>
			{t('LOCATION.HELPER_TEXT')}
		</h1>
	);

	const featureHelperText = (
		<>
			<h1 style={{ color: "#000000" }}>
				{t('FEATURES.HELPER_TEXT_1')}{" "}
				<a href={`mailto:sarah@tastet.ca?subject=${t('EMAIL.LIST_MODIFICATION_SUBJECT')}`}>
					{" "}
					{t('BUTTONS.CONTACT_US')}
				</a>{". "}
				{t('FEATURES.HELPER_TEXT_2')}
			</h1>{" "}
		</>
	);

	return (
		<>
			<DashboardSection>
				<Box
					sx={{
						display: "flex",
						flexWrap: "wrap",
						justifyContent: "space-evenly",
						alignItems: "center",
					}}>
					<TextField
						variant={"h1"}
						sx={{
							textAlign: "center",
						}}>
						{location?.state?.name} {t('ESTABLISHMENT_INFORMATION.SUMMARY')}
					</TextField>
					{/* default url to english review */}

					{location?.state?.articles?.reviews[0]?.url && (
						<StyledExternalLink
							href={location?.state?.articles.reviews[0].url}
							target="_blank">
							<TextField variant={"p"} sx={{ fontSize: "1rem" }}>
								{t('BUTTONS.VIEW_ARTICLE')}
							</TextField>
						</StyledExternalLink>
					)}
				</Box>
				{location?.state?.media?.gallery[0] !== null && <> <Carousel images={location?.state?.media?.gallery} back={t('BUTTONS.BACK')} next={t('BUTTONS.NEXT')}></Carousel>
					<Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
						<StyledMailto
							customLinkStyle={{ width: "fit-content" }}
							href={`mailto:sarah@tastet.ca?subject=${t('EMAIL.PICTURE_PURCHASE_SUBJECT')}`}
						>
							{t('BUTTONS.PURCHASE_PICTURES')}
						</StyledMailto>
					</Box></>}
			</DashboardSection>
			<DashboardSection>
				<StyledDashBoardSectionTitleContainer>
					<TextField
						variant={"h3"}
						sx={{
							textAlign: { xs: "center", md: "center", lg: "left" },
						}}>
						{t('ESTABLISHMENT_INFORMATION.TITLE')}
					</TextField>
				</StyledDashBoardSectionTitleContainer>
				<DashboardSection
					customStyle={{
						display: "flex",
						flexWrap: "wrap",
						mt: "10px",
						borderBottom: "solid",
						justifyContent: "flex-start",
						borderColor: theme.palette.color_grey,
					}}>
					<DashboardSubSection
					//customStyle={{ width: { xs: "100%", lg: "50%" } }}
					>
						<TextField
							variant={"h4"}
							sx={{
								fontSize: " 1.4rem",
								textAlign: { xs: "center", md: "center", lg: "left" },
							}}>
							{t('ESTABLISHMENT_INFORMATION.STAFF_INFORMATION.TITLE')}
						</TextField>
						<DashboardTextWrapper>
							<Label>{t('ESTABLISHMENT_INFORMATION.STAFF_INFORMATION.OWNER')}(s):</Label>

							{location?.state?.ownerName.map((owner, index) => (
								<React.Fragment key={index}>
									<TextField variant={"p"}>
										{"   "}
										{(index ? ", " : "") + owner}
									</TextField>
								</React.Fragment>
							))}
						</DashboardTextWrapper>
						<DashboardTextWrapper>
							<Label>{t('ESTABLISHMENT_INFORMATION.STAFF_INFORMATION.CHEF')}(s):</Label>
							{location?.state?.chefName.map((chef, index) => (
								<React.Fragment key={index}>
									<TextField variant={"p"} key={index}>
										{(index ? ", " : "") + chef}
									</TextField>
								</React.Fragment>
							))}
						</DashboardTextWrapper>
						<DashboardTextWrapper>
							<Label>{t('ESTABLISHMENT_INFORMATION.STAFF_INFORMATION.PASTRY_CHEF')}:</Label>
							<TextField variant={"p"}>
								{location?.state?.pastryChefName
									? location?.state?.pastryChefName
									: ""}
							</TextField>
						</DashboardTextWrapper>
						<DashboardTextWrapper>
							<Label>{t('ESTABLISHMENT_INFORMATION.STAFF_INFORMATION.BARTENDER')}:</Label>
							<TextField variant={"p"}>
								{location?.state?.bartenderName}
							</TextField>
						</DashboardTextWrapper>
						<DashboardTextWrapper>
							<Label>{t('ESTABLISHMENT_INFORMATION.STAFF_INFORMATION.SOMMELIER')}:</Label>
							<TextField variant={"p"}>
								{location?.state?.sommelierName}
							</TextField>
						</DashboardTextWrapper>
						<DashboardTextWrapper>
							<Label>{t('ESTABLISHMENT_INFORMATION.STAFF_INFORMATION.GENERAL_MANAGER')}:</Label>
							<TextField variant={"p"}>{location?.state?.gmName}</TextField>
						</DashboardTextWrapper>
						<DashboardTextWrapper>
							<Label>{t('ESTABLISHMENT_INFORMATION.STAFF_INFORMATION.FLOOR_MANAGER')}:</Label>
							<TextField variant={"p"}>
								{location?.state?.floorManagerName
									? location?.state?.floorManagerName
									: ""}
							</TextField>
						</DashboardTextWrapper>
						<DashboardTextWrapper>
							<Label>{t('ESTABLISHMENT_INFORMATION.STAFF_INFORMATION.DESIGNER')}:</Label>
							<TextField variant={"p"}>
								{location?.state?.designer
									? location?.state?.designer
									: ""}
							</TextField>
						</DashboardTextWrapper>

						<DashboardTextWrapper>
							<Label>{t('ESTABLISHMENT_INFORMATION.STAFF_INFORMATION.SPECIAL_MENTIONS')}:</Label>
							{objectKeyAsDisplayIfValueTrue(location?.state?.private.socialEquality, 'socialEquality')}
						</DashboardTextWrapper>
					</DashboardSubSection>
				</DashboardSection>
				<DashboardSection
					customStyle={{
						display: "flex",
						flexDirection: "column",
						flexWrap: "wrap",
						mt: "10px",
						borderBottom: "solid",
						borderColor: theme.palette.color_grey,
					}}>
					<TextField
						variant={"h4"}
						sx={{
							fontSize: " 1.4rem",
							textAlign: { xs: "center", md: "center", lg: "left" },
							height: 'fit-content',
							mb: "15px"
						}}>
						{t('OTHER_INFORMATION.TITLE')}
					</TextField>
					<DashboardSubSection customStyle={{ display: "flex", flexDirection: { xs: "column", lg: "row" } }}>
						<DashboardSubSection
							customStyle={{ width: { xs: "100%", lg: "50%" }, mr: "150px" }}>


							<DashboardTextWrapper>
								<Label>{t('OTHER_INFORMATION.LABELS.MERCHANT_TYPE')}:</Label>
								{objectKeyAsDisplayIfValueTrue(
									location?.state?.private.merchantType, 'merchantType'
								)}
							</DashboardTextWrapper>
							<DashboardTextWrapper>
								<Label>{t('OTHER_INFORMATION.LABELS.CUISINE_TYPE')}:</Label>
								{objectKeyAsDisplayIfValueTrue(
									location?.state?.private.food.cuisineType, 'cuisineType'
								)}
							</DashboardTextWrapper>
							<DashboardTextWrapper>
								<Label>{t('OTHER_INFORMATION.LABELS.MENU_DYNAMISM')}:</Label>
								<TextField variant={"p"}>
									{objectKeyAsDisplayIfValueTrue(location?.state?.menuDynamism, 'menuDynamism')}
								</TextField>
							</DashboardTextWrapper>
							<DashboardTextWrapper>
								<Label>{t('OTHER_INFORMATION.LABELS.TASTING_MENU')}:</Label>
								<TextField variant={"p"}>
									{location?.state?.tastingMenu === "true" || location?.state?.tastingMenu === true || location?.state?.tastingMenu === "yes" ? t('VALUES.YES') : t('VALUES.NO')}
								</TextField>
							</DashboardTextWrapper>

							<DashboardTextWrapper>
								<Label>{t('OTHER_INFORMATION.LABELS.INTERIOR_SEATING')}:</Label>
								<TextField variant={"p"}>{location?.state?.seatNumber}</TextField>
							</DashboardTextWrapper>
							<DashboardTextWrapper>
								<Label>{t('OTHER_INFORMATION.LABELS.TERRASSE_SEATING')}:</Label>
								<TextField variant={"p"}>
									{location?.state?.seatTerrasseNumber}
								</TextField>
							</DashboardTextWrapper>
							<DashboardTextWrapper customStyle={{ alignItems: { xs: "center", lg: "center" } }}>
								<Label >{t('OTHER_INFORMATION.LABELS.PRIVATE_ROOM_SEATING')}:</Label>
								<TextField variant={"p"}>
									{location?.state?.seatPrivateNumber}
								</TextField>
							</DashboardTextWrapper>
							<DashboardTextWrapper>
								<Label>{t('OTHER_INFORMATION.LABELS.PAYMENT_TYPE')}:</Label>
								{objectKeyAsDisplayIfValueTrue(location?.state?.paymentTypes, 'paymentTypes')}
							</DashboardTextWrapper>
							<DashboardTextWrapper>
								<Label>{t('OTHER_INFORMATION.LABELS.OPENING_DATE')}:</Label>
								<TextField variant={"p"}>
									{location?.state?.openingDate}
								</TextField>
							</DashboardTextWrapper>
						</DashboardSubSection>
						<DashboardSubSection
							customStyle={{
								width: { xs: "100%", lg: "50%" },
								ml: { lg: "150px" }
							}}>
							{/* <DashboardTextWrapper>
							<Label>Reservations:</Label>
							<TextField variant={"p"}>
								{location?.state?.reservationOffered ? "yes" : "no"}
							</TextField>
						</DashboardTextWrapper> */}

							<DashboardTextWrapper>
								<Label>{t('OTHER_INFORMATION.LABELS.CATERING')}:</Label>
								<TextField variant={"p"}>
									{location?.state?.catering === "yes" || location?.state?.catering === "true" || location?.state?.catering === true ? t('VALUES.YES') : t('VALUES.NO')}
								</TextField>
							</DashboardTextWrapper>


							<DashboardTextWrapper>
								<Label>{t('OTHER_INFORMATION.LABELS.GROUP_FRIENDLY')}:</Label>
								<TextField variant={"p"}>{location?.state?.groups === "yes" || location?.state?.groups === "true" || location?.state?.groups === true ? t('VALUES.YES') : t('VALUES.NO')}</TextField>
							</DashboardTextWrapper>
							<DashboardTextWrapper>
								<Label>{t('OTHER_INFORMATION.LABELS.MAX_PPL_GROUP')}:</Label>
								<TextField variant={"p"}>
									{location?.state?.maxPersonPerGroup}
								</TextField>
							</DashboardTextWrapper>
							{/* <DashboardTextWrapper>
							<Label>Deliveries:</Label>
							<TextField variant={"p"}>
								{location?.state?.deliveryOffered ? "yes" : "no"}
							</TextField>
						</DashboardTextWrapper> */}


							<DashboardTextWrapper>
								<Label>{t('OTHER_INFORMATION.LABELS.RESTO_PRIVATIZATION')}:</Label>
								<TextField variant={"p"}>
									{location?.state?.restaurantPrivatization === "yes" || location?.state?.restaurantPrivatization === "true" || location?.state?.restaurantPrivatization === true ? t('VALUES.YES') : t('VALUES.NO')}
								</TextField>
							</DashboardTextWrapper>

							<DashboardTextWrapper  >
								<Label>{t('OTHER_INFORMATION.LABELS.MENU_LINK')}:</Label>
								<TextField variant={"p"} sx={{ wordBreak: "break-word", }}>
									{location?.state?.restaurantMenuLink}
								</TextField>
							</DashboardTextWrapper>

							<DashboardTextWrapper  >
								<Label>{t('OTHER_INFORMATION.LABELS.FACEBOOK')}:</Label>
								<TextField variant={"p"} sx={{ wordBreak: "break-word", }}>
									{location?.state?.socialMedia.facebook}
								</TextField>
							</DashboardTextWrapper>
							<DashboardTextWrapper  >
								<Label>{t('OTHER_INFORMATION.LABELS.INSTAGRAM')}:</Label>
								<TextField variant={"p"} sx={{ wordBreak: "break-word", }}>
									{location?.state?.socialMedia.instagram}
								</TextField>
							</DashboardTextWrapper>
							<DashboardTextWrapper>
								<Label>{t('OTHER_INFORMATION.LABELS.WIFI')}:</Label>
								<TextField variant={"p"}>{location?.state?.wifi === "yes" || location?.state?.wifi === "true" || location?.state?.wifi === true ? t('VALUES.YES') : t('VALUES.NO')}</TextField>
							</DashboardTextWrapper>
							<DashboardTextWrapper>
								<Label>{t('OTHER_INFORMATION.LABELS.ACCESSIBLE_2')}:</Label>
								<TextField variant={"p"}>
									{location?.state?.isAccessible === "yes" || location?.state?.isAccessible === "true" || location?.state?.isAccessible === true ? t('VALUES.YES') : t('VALUES.NO')}
								</TextField>
							</DashboardTextWrapper>
						</DashboardSubSection>
					</DashboardSubSection>
					<DashboardSubSection
						customStyle={{

							display: "flex",
							justifyContent: "center",
							m: "25px",
						}}>
						<StyledLink
							href={`${MERCHANT_DASHBOARD_RESTAURANT_EDIT}${location?.state?.publicId}`}
							state={location.state}>
							{t('BUTTONS.EDIT_INFORMATION')}
						</StyledLink>
					</DashboardSubSection>

				</DashboardSection>
				<DashboardSection
					customStyle={{
						display: "flex",
						flexWrap: "wrap",
						mt: "10px",
						borderBottom: "solid",
						borderColor: theme.palette.color_grey,
					}}>
					<DashboardSubSection
						customStyle={{ width: { xs: "100%", lg: "100%" } }}>
						<TextField
							variant={"h4"}
							sx={{
								fontSize: " 1.4rem",
								textAlign: { xs: "center", md: "center", lg: "left" },
							}}>
							{t('LOCATION.TITLE')} <BasicTooltip text={locationHelperText} />
						</TextField>
						<DashboardTextWrapper>
							<Label>{t('LOCATION.WEBSITE')}:</Label>
							<TextField variant={"p"}>{location?.state?.website}</TextField>
						</DashboardTextWrapper>
						<DashboardTextWrapper>
							<Label>{t('LOCATION.PHONE_NUMBER')}:</Label>
							<TextField variant={"p"}>{location?.state?.phone}</TextField>
						</DashboardTextWrapper>
						<DashboardTextWrapper>
							<Label>{t('LOCATION.ADDRESS')}:</Label>
							<TextField variant={"p"}>
								{location?.state?.location.address.streetNumber +
									", " +
									location?.state?.location.address.street.default +
									", " +
									location?.state?.location.address.city.default +
									", " +
									location?.state?.location.address.stateProvince.default +
									", " +
									location?.state?.location.address.zip.default}
							</TextField>
						</DashboardTextWrapper>
						<DashboardTextWrapper customStyle={{ alignItems: "flex-start" }}>
							<Label>{t('LOCATION.HOURS')}:</Label>
							<Box sx={{ display: "flex", flexDirection: "column" }}>
								{schedule?.map((day, index) => (
									<React.Fragment key={index}>
										<TextField
											variant={"p"}
											sx={index !== 0 ? { m: { sx: "0px", md: "5px", lg: "5px" } } : { m: { sx: "0px", md: "5px", lg: "0px 5px 5px" } }}>
											{i18n.language === "en" ? day[1].en : day[1].fr}
										</TextField>
									</React.Fragment>
								))}
							</Box>
						</DashboardTextWrapper>
					</DashboardSubSection>
					{/* <DashboardSubSection
						customStyle={{ width: { xs: "100%", lg: "50%" } }}>
					
						<img
							height="80%"
							width="80%"
							alt="location of the restaurant on a map"
							src={`https://maps.googleapis.com/maps/api/staticmap?style=feature:poi%7Cvisibility:off&scale=2&format=jpg&maptype=roadmap&size=515x370&markers=icon:https://tastet.ca/pin.png%7C${location?.state?.location.coordinates.latitude},${location?.state?.location.coordinates.longitude}&key=AIzaSyBZFdN8Ij2wNxFBb7u44neeEaU_7uKJRwM`}></img>
					</DashboardSubSection> */}
				</DashboardSection>
			</DashboardSection>

			{location?.state?.articles?.lists?.length > 0 && (
				<DashboardSection customStyle={{ mt: "10px" }}>
					<StyledDashBoardSectionTitleContainer>
						<TextField
							variant={"h3"}
							sx={{
								textAlign: { xs: "center", md: "center", lg: "left" },
							}}>
							{t('FEATURES.TITLE')} <BasicTooltip text={featureHelperText} />
						</TextField>
					</StyledDashBoardSectionTitleContainer>
					<DashboardSubSection>
						{location?.state?.articles.lists.map((list) => (
							<div style={{ marginTop: "10px" }}>
								<a href={list.url} target="_blank">
									{list.url}
								</a>
							</div>
						))}
					</DashboardSubSection>
				</DashboardSection>
			)}
		</>
	);
};
export default MerchantRestaurantDetailsPage;

