import * as React from "react";
import { useEffect, useState } from "react";
import Checkbox from "@mui/material/Checkbox";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Chip from "@mui/material/Chip";
import { useTranslation } from "react-i18next";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const DropDownSelect = ({ label, options, handleChange, valueToChange }) => {
	const { t, i18n } = useTranslation();
	const displayLabel = (option) => {
		let label
		switch (valueToChange) {
			case "specialMentions":
				label = t(`ESTABLISHMENT_INFORMATION.SOCIAL_EQUALITY.OPTIONS.${option[0]}`)
				console.log('label', label)
				break;
			case "merchantType":
				label = t(`OTHER_INFORMATION.MERCHANT_TYPE_OPTIONS.${option[0]}`)
				break;
			case "foodType":
				label = t(`OTHER_INFORMATION.CUISINE_TYPE_OPTIONS.${option[0]}`)
				break;
			case "menuDynamism":
				label = t(`OTHER_INFORMATION.MENU_DYNAMISM_OPTIONS.${option[0]}`)
				break;


		}

		return label

	}

	return (
		<Autocomplete
			sx={{ width: { xs: "100%", md: "100%", lg: "300px" }, paddingTop: "24px" }}
			multiple
			disableClearable
			id="checkboxes-tags-demo"
			options={options}
			disableCloseOnSelect
			getOptionLabel={(option) => option[0]}
			value={options.filter(
				(option) =>
					option[1] === "yes" || option[1] === "true" || option[1] === true
			)}
			renderTags={(values) =>
				values.map((value, index) => (
					<Chip
						key={value[0]}
						label={value[2] ? displayLabel(value) : value[0]}
						onDelete={() => {
							handleChange(value, "unchecked", valueToChange);
						}}
					/>
				))
			}
			isOptionEqualToValue={(option, value) =>
				(option[1] === "yes" && value[1] === "true") || value[1] === true
			}
			renderOption={(props, option, { selected }) => (
				<li
					{...props}
					onClick={() => handleChange(option, "checked", valueToChange)}>
					<FormControlLabel
						control={
							<Checkbox
								icon={icon}
								checkedIcon={checkedIcon}
								style={{ marginRight: 8 }}
								checked={
									option[1] === "yes" ||
									option[1] === "true" ||
									option[1] === true
								}
							/>
						}
						label={displayLabel(option)}
					/>
				</li>
			)}
			// style={{ width: 300, paddingTop: "24px" }}
			renderInput={(params) => <TextField {...params} label={label} />}
		/>
	);
};

export default DropDownSelect;
