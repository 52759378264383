import Box from "@mui/material/Box";
import StyledLink from "../links/styled-link/StyledLink";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import BookmarkBorderOutlinedIcon from "@mui/icons-material/BookmarkBorderOutlined";
import {
	MERCHANT_DASHBOARD_RESTAURANT_DETAILS,
	MERCHANT_DASHBOARD_RESTAURANT_EDIT,
} from "../../config/routes";
const RestoCard = ({ restaurant, view, edit }) => {
	const defaultBoxStyle = {
		display: "flex",
		flexDirection: "column",
		padding: "6px 8px",
		m: { xs: "0 0 10px 0", md: "0 0 10px 0" },
		width: { xs: "100%", md: "100%", lg: "300px" },
		flexGrow: "0.5"
	};

	const statsWrapper = {
		display: "flex",
		justifyContent: "space-between",
		mt: { xs: "10px", md: "10px" },
	};
	const stats = {
		display: "flex",
		alignItems: "center",
	};

	const linksWrapper = {
		display: "flex",
		justifyContent: { xs: "space-around", md: "space-around" },
	};
	return (
		<Box sx={{ ...defaultBoxStyle }}>
			<img
				style={{ maxWidth: "inherit", objectFit: "cover", height: "300px" }}
				src={restaurant.media.gallery[0]?.url ? restaurant.media.gallery[0].url : null
				}
				alt="restaurant/food"
			/>
			<span>{restaurant.name}</span>
			<span>{restaurant.location.address.city.default}</span>

			<Box sx={{ ...linksWrapper }}>
				<StyledLink
					customLinkStyle={{
						width: "50%",
						m: "5px 5px 0 5px",
					}}
					href={`${MERCHANT_DASHBOARD_RESTAURANT_DETAILS}${restaurant.publicId}`}
					state={restaurant}>
					{view}
				</StyledLink>
				<StyledLink
					customLinkStyle={{ width: "50%", m: "5px 5px 0 5px" }}
					href={`${MERCHANT_DASHBOARD_RESTAURANT_EDIT}${restaurant.publicId}`}
					state={restaurant}>
					{edit}
				</StyledLink>
			</Box>
		</Box>
	);
};
export default RestoCard;
/*	<Box sx={{ ...statsWrapper }}>
			<Box sx={{ ...stats }}>
				{props.views}
				<RemoveRedEyeIcon />
			</Box>
			<Box sx={{ ...stats }}>
				{props.likes}
				<FavoriteBorderIcon />
			</Box>
			<Box sx={{ ...stats }}>
				{props.bookMarks}
				<BookmarkBorderOutlinedIcon />
			</Box>
		</Box> */
