
import Link from "@mui/material/Link";
import { useTheme } from "@mui/material";
const StyledMailto = ({ customStyle, href, children }) => {
    const theme = useTheme();
    const defaultStyle = {
        display: "flex",
        justifyContent: "center",
        color: "black",
        backgroundColor: theme.palette.color_yellow,
        borderRadius: "6px",
        padding: "14px 8px",
        m: { xs: "5px 5px 5px 5px", md: "0 0 10px 0" },
        boxShadow: "2px 3px #888888",
        width: { xs: "100%", md: "100%", lg: "fit-content" },
        cursor: "pointer",
        textDecoration: "none",
        alignItems: 'center'
    }

    return (<Link sx={{ ...defaultStyle, ...customStyle }} href={href} >{children}</Link>)
}
export default StyledMailto