import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { useTheme } from "@mui/material/styles";
const ListHeaderComponent = ({ tableHeaders, darkMode }) => {
	const theme = useTheme();

	const customTableHeadStyle = {
		backgroundColor: darkMode ? theme.palette.color_green : null,
	};

	const customTableCellStyle = {
		color: darkMode ? theme.palette.color_yellow : null,
	};
	return (
		<TableHead sx={{ ...customTableHeadStyle }}>
			<TableRow>
				{tableHeaders.map((tableHead, index) => (
					<TableCell
						sx={{ ...customTableCellStyle }}
						align="center"
						key={index}>
						{tableHead}
					</TableCell>
				))}
			</TableRow>
		</TableHead>
	);
};
export default ListHeaderComponent;
