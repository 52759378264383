import { useEffect, useState } from "react";

import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DialogTitle from "@mui/material/DialogTitle";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import i18next from "i18next";
import { useTranslation } from "react-i18next";

const AlertModal = ({ open, setOpen, email, error, setError }) => {
	const { t, i18n } = useTranslation();
	const handleClose = () => {
		setOpen(!open);
		setError("");
	};

	if (error) {
		return (
			<Dialog
				open={open}
				onClose={() => handleClose()}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description">
				<DialogTitle
					id="alert-dialog-title"
					sx={{ textAlign: "center", backgroundColor: "#f4b8b8" }}>
					{t("ALERTS.SIGN_IN_ERROR.SIGN_IN_ERROR_1")}
				</DialogTitle>

				<DialogContent
					sx={{
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
						backgroundColor: "#f4b8b8",
					}}>
					<ErrorOutlineIcon
						sx={{
							fontSize: 55,
							color: "red",
						}}></ErrorOutlineIcon>
					<DialogContentText
						id="alert-dialog-description"
						sx={{ textAlign: "center" }}>
						{t("ALERTS.SIGN_IN_ERROR.SIGN_IN_ERROR_2")}
						{`${email}`}
						{t("ALERTS.SIGN_IN_ERROR.SIGN_IN_ERROR_3")}
						<a href={`mailto:sarah@tastet.ca?subject=${t('EMAIL.LOGIN_ISSUES_SUBJECT')}`}>
							sarah@tastet.ca
						</a>{" "}
						{t("ALERTS.SIGN_IN_ERROR.SIGN_IN_ERROR_4")}
					</DialogContentText>
				</DialogContent>
				<DialogActions
					sx={{ backgroundColor: "#f4b8b8", justifyContent: "center" }}>
					<Button onClick={() => handleClose()}>Close</Button>
				</DialogActions>
			</Dialog>
		);
	} else
		return (
			<>
				<Dialog
					open={open}
					onClose={() => handleClose()}
					aria-labelledby="alert-dialog-title"
					aria-describedby="alert-dialog-description">
					<DialogTitle id="alert-dialog-title" sx={{ textAlign: "center" }}>
						{t("ALERTS.SIGN_IN_SUCCESS.SIGN_IN_SUCCESS_1")}
					</DialogTitle>

					<DialogContent
						sx={{
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
						}}>
						<CheckCircleIcon
							sx={{
								fontSize: 55,
								color: "green",
							}}></CheckCircleIcon>
						<DialogContentText
							id="alert-dialog-description"
							sx={{ textAlign: "center" }}>
							{t("ALERTS.SIGN_IN_SUCCESS.SIGN_IN_SUCCESS_2")}{`${email}`}{t("ALERTS.SIGN_IN_SUCCESS.SIGN_IN_SUCCESS_3")}
							<br></br>
							<b>{t("ALERTS.SIGN_IN_SUCCESS.SIGN_IN_SUCCESS_4")}</b>

						</DialogContentText>
					</DialogContent>
					<DialogActions sx={{ justifyContent: "center" }}>
						<Button onClick={() => handleClose()}>{t("BUTTONS.CLOSE")}</Button>
					</DialogActions>
				</Dialog>
			</>
		);
};

export default AlertModal;
