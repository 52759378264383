import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useTheme } from "@mui/material/styles";
import TextField from "../text-fields/TextField";

const FilterAccordion = ({ children, customStyle }) => {
	const theme = useTheme();

	const defaultFilterAccordion = {
		backgroundColor: theme.palette.color_grey,
		mb: "20px",
	};
	return (
		<Accordion sx={{ ...defaultFilterAccordion, ...customStyle }}>
			{children}
		</Accordion>
	);
};
export default FilterAccordion;
