import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import ListComponent from "../../list/ListComponent";

const DropDownSearchInput = ({
	label,
	customStyle,
	tableHeaders,
	isDashboard,
	isList,
	isAdmin,
	setSearchTerm,
	suggestions,
	initialSuggestions,
	searchTerm,
}) => {
	const defaultStyle = {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		mt: { xs: "60px", md: "60px" },
		width: { xs: "100%", md: "100%", lg: "100%" },
	};

	const searchBarStyle = {
		width: "100%",
		borderRadius: "50px",
		padding: "7px",
		backgroundColor: "#edff22",
		height: "50px",
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
	};

	return (
		<Box sx={{ ...defaultStyle, ...customStyle }} fullWidth>
			<Box sx={{ ...searchBarStyle }}>
				<FormControl>
					<InputBase
						type="text"
						placeholder="restaurants..."
						autoComplete="off"
						onChange={(event) => {
							setSearchTerm(event.target.value);
						}}
						value={searchTerm}></InputBase>
				</FormControl>
				<SearchIcon />
			</Box>
			<ListComponent
				items={suggestions.length !== 0 ? suggestions : initialSuggestions}
				isList={isList}
				isAdmin={isAdmin}
				searchTerm={searchTerm}
				tableHeaders={tableHeaders}
				isDashboard={isDashboard}></ListComponent>
		</Box>
	);
};
export default DropDownSearchInput;
