import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const AccordionComponent = ({ children }) => {
	const defaultAccordionStyle = {
		mb: "20px",
	};
	return (
		<div>
			<Accordion sx={{ ...defaultAccordionStyle }}>{children}</Accordion>
		</div>
	);
};
export default AccordionComponent;
