import Modal from "@mui/material/Modal";
import BasicInput from "../inputs/basic-input/BasicInput";
import LoginInput from "../inputs/login-input/LoginInput";
import StyledButton from "../buttons/StyledButtonComponent";
import TextField from "../text-fields/TextField";
import SuccessModal from "./alert";
import { FormControl } from '@mui/material';
import { useTranslation } from "react-i18next";
import { FormGroup } from '@mui/material';

import Box from "@mui/material/Box";
const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: 400,
	bgcolor: "background.paper",
	border: "2px solid #000",
	boxShadow: 24,
	p: 4,
	display: "flex",
	flexDirection: "column",
	alignItems: "center",
};
const UserProfile = ({
	open,
	onClose,
	userProfile,
	setUserProfile,
	submitProfileChange,
	error,
	setInputError,
	inputError
}) => {
	const { t, i18n } = useTranslation();
	const handleProfileChange = (i, e, valueToChange) => {
		setUserProfile((prevState) => ({
			...prevState,
			[valueToChange]: e.target.value,
		}));
	};

	return (
		<>
			<Modal
				open={open}
				onClose={onClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description">
				<Box sx={style}>
					<Box sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
						<TextField variant={"h3"}>{t('PROFILE.TITLE')}</TextField>
						<button style={{ padding: "none", border: "none", background: "none", cursor: "pointer" }} onClick={() => onClose()}>X</button>
					</Box>
					<Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }} onSubmit={(e) => submitProfileChange(e)}>
						<form>
							<BasicInput
								label={t('LABELS.FIRST_NAME')}
								value={userProfile?.first_name}
								valueToChange="first_name"
								handleChange={handleProfileChange}></BasicInput>
							<BasicInput
								label={t('LABELS.LAST_NAME')}
								value={userProfile?.last_name}
								valueToChange="last_name"
								handleChange={handleProfileChange}></BasicInput>
							<BasicInput
								label={t('LABELS.EMAIL')}
								value={userProfile?.email}
								valueToChange="email"
								error={inputError}
								handleChange={handleProfileChange}></BasicInput>
							{inputError && <TextField sx={{ color: "red", maxWidth: " 300px" }}>
								{t('INPUT_MESSAGES.EMAIL_ERROR')}
							</TextField>}
							<TextField sx={{ maxWidth: " 300px" }}>
								{t('INPUT_MESSAGES.EMAIL_WARNING')}
							</TextField>
							<BasicInput
								label={t('LABELS.PHONE')}
								value={userProfile?.phone}
								valueToChange="phone"
								type="tel"
								handleChange={handleProfileChange}></BasicInput>
							<StyledButton type={"submit"} sx={{ marginTop: "20px" }}>
								{t('BUTTONS.SAVE_CHANGES')}
							</StyledButton>
						</form>
					</Box>
				</Box>

			</Modal >
		</>
	);
};
export default UserProfile;
