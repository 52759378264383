import Box from "@mui/material/Box";
const DashboardTextWrapper = ({ customStyle, children }) => {
	const defaultTextWrapperStyle = {
		display: "flex",
		flexWrap: "wrap",
		alignItems: "center",
		padding: "5px 0px"
	};
	return (
		<Box sx={{ ...defaultTextWrapperStyle, ...customStyle }}>{children}</Box>
	);
};
export default DashboardTextWrapper;
