import Button from "@mui/material/Button";
import { useTheme } from "@mui/material/styles";

const AddButton = ({ onClick, type, title }) => {
	const theme = useTheme();

	const defaultStyle = {
		borderRadius: "60%",
		backgroundColor: theme.palette.color_yellow,
		color: theme.palette.color_black,
		border: "none",
		minHeight: "20px",
		minWidth: "40px",
		mt: "10px",
	};
	return (
		<Button
			sx={defaultStyle}
			onClick={() => onClick()}
			type={type}
			title={title}>
			+
		</Button>
	);
};
export default AddButton;
