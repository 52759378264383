import Typography from "@mui/material/Typography";

const Label = ({ sx, variant, children }) => {
	const defaultStyle = {
		textAlign: { xs: "left", md: "center", lg: "left" },
		marginRight: "5px",
		marginTop: "-3px",
	};

	return (
		<Typography sx={{ ...defaultStyle, ...sx }} variant="h3">
			{children}
		</Typography>
	);
};
export default Label;
