import * as React from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MobileStepper from "@mui/material/MobileStepper";
import Button from "@mui/material/Button";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import SwipeableViews from "react-swipeable-views";

const AutoPlaySwipeableViews = SwipeableViews;

const Carousel = ({ images, next, back }) => {
	const theme = useTheme();
	const [activeStep, setActiveStep] = React.useState(0);

	const perSubset = 3; // items per subset

	const subsetsOfImagesArray = images?.reduce((resultArray, item, index) => {
		const chunkIndex = Math.floor(index / perSubset);

		if (!resultArray[chunkIndex]) {
			resultArray[chunkIndex] = []; // start a new subset
		}

		resultArray[chunkIndex].push(item);

		return resultArray;
	}, []);

	const maxSteps = images?.length;
	const desktopSteps = subsetsOfImagesArray?.length;

	const handleNext = () => {
		setActiveStep((prevActiveStep) => prevActiveStep + 1);
	};

	const handleBack = () => {
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
	};

	const handleStepChange = (step) => {
		setActiveStep(step);
	};

	return (
		<>
			<Box
				sx={{
					maxWidth: { md: 400 },
					flexGrow: { xs: 1, md: 1 },
					display: { lg: "none" },
				}}>
				<AutoPlaySwipeableViews
					axis={theme.direction === "rtl" ? "x-reverse" : "x"}
					index={activeStep}
					onChangeIndex={handleStepChange}
					enableMouseEvents>
					{images?.map((step, index) => (
						<div key={step.label}>
							{Math.abs(activeStep - index) <= 2 ? (
								<Box
									component="img"
									loading="lazy"
									sx={{
										height: 255,
										display: "block",
										maxWidth: { lg: 400 },
										overflow: "hidden",
										width: "100%",
										objectFit: { xs: "contain" }
									}}
									src={step.url}
									alt={step.alt.en || step.alt.fr}
								/>
							) : null}
						</div>
					))}
				</AutoPlaySwipeableViews>
				<MobileStepper
					sx={{ backgroundColor: theme.palette.color_beige }}
					steps={maxSteps}
					position="static"
					activeStep={activeStep}
					nextButton={
						<Button
							size="small"
							onClick={handleNext}
							disabled={activeStep === maxSteps - 1}>
							{next}
							{theme.direction === "rtl" ? (
								<KeyboardArrowLeft />
							) : (
								<KeyboardArrowRight />
							)}
						</Button>
					}
					backButton={
						<Button
							size="small"
							onClick={handleBack}
							disabled={activeStep === 0}>
							{theme.direction === "rtl" ? (
								<KeyboardArrowRight />
							) : (
								<KeyboardArrowLeft />
							)}
							{back}
						</Button>
					}
				/>
			</Box>

			<Box
				sx={{
					maxWidth: { lg: "100%" },
					flexGrow: { lg: 1 },
					display: { xs: "none", md: "none", lg: "block" },
				}}>
				<AutoPlaySwipeableViews
					axis={theme.direction === "rtl" ? "x-reverse" : "x"}
					index={activeStep}
					onChangeIndex={handleStepChange}
					enableMouseEvents>
					{subsetsOfImagesArray?.map((step, index) => (
						<>
							<>
								{Math.abs(activeStep - index) <= 3 ? (
									<>
										<Box
											component="img"
											loading="lazy"
											sx={{
												objectFit: "cover",
												padding: "3px",
												width: "33%",
												maxHeight: "500px",
												height: "600px",
											}}
											src={step[0]?.url}
										/>
										<Box
											component="img"
											loading="lazy"
											sx={{
												objectFit: "cover",
												padding: "3px",
												width: "33%",
												maxHeight: "500px",
												height: "600px",
											}}
											src={step[1]?.url}
										/>
										<Box
											component="img"
											loading="lazy"
											sx={{
												objectFit: "cover",
												padding: "3px",
												width: "33%",
												maxHeight: "500px",
												height: "600px",
											}}
											src={step[2]?.url}
										/>
									</>
								) : null}
							</>
						</>
					))}
				</AutoPlaySwipeableViews>
				<Box>
					<MobileStepper
						sx={{ backgroundColor: theme.palette.color_beige }}
						steps={desktopSteps}
						position="static"
						activeStep={activeStep}
						nextButton={
							<Button
								size="small"
								onClick={handleNext}
								disabled={activeStep === desktopSteps - 1}>
								{next}
								{theme.direction === "rtl" ? (
									<KeyboardArrowLeft />
								) : (
									<KeyboardArrowRight />
								)}
							</Button>
						}
						backButton={
							<Button
								size="small"
								onClick={handleBack}
								disabled={activeStep === 0}>
								{theme.direction === "rtl" ? (
									<KeyboardArrowRight />
								) : (
									<KeyboardArrowLeft />
								)}
								{back}
							</Button>
						}
					/>
				</Box>
			</Box>
		</>
	);
};

export default Carousel;

/**
 *
 *
 * alt={step.alt.en || step.alt.fr}
 * 
 * 
 * 
 * 
 * 		{Math.abs(activeStep - index) <= 2 ? (
								<>
									<Box
										key={index}
										component="img"
										sx={{
											objectFit: "cover",
											padding: "3px",
											width: "33%",
										}}
										src={step.url}
									/>
								</>
							) : null}
 * 
 * 
 * 
 *
 */
