import AccordionDetails from "@mui/material/AccordionDetails";
const AccordionDetailsComponent = ({ children }) => {
	const defaultAccordionDetailsStyle = {
		mb: "20px",
	};
	return (
		<AccordionDetails sx={{ ...defaultAccordionDetailsStyle }}>
			{children}
		</AccordionDetails>
	);
};
export default AccordionDetailsComponent;
