import { createRef, useState } from "react";
import Box from "@mui/material/Box";
import { InputLabel } from "@mui/material";
import InputBase from "@mui/material/InputBase";
import FormControl from "@mui/material/FormControl";

const BasicInput = ({
	required,
	label,
	sx,
	onChange,
	value,
	name,
	onChangeMap,
	index,
	handleChange,
	valueToChange,
	type,
	helperText,
	error
}) => {
	const inputRef = createRef();
	const defaultStyle = {
		width: { xs: "100%", md: "100%", lg: "300px" },
		borderRadius: "5px",
		padding: "7px",
		backgroundColor: "white",
		height: "50px",
		//	display: "flex",
		//justifyContent: "space-between",
		//alignItems: "center",
		border: "solid",
		borderWidth: "1px",
		mt: { xs: "60px", md: "60px" },
	};

	return (
		<Box sx={{ ...defaultStyle, ...sx }}>
			<FormControl required={required} sx={{ width: "100%" }} error={error}>
				<InputLabel htmlFor={label} error={error}>{label}</InputLabel>
				<InputBase
					required={required}
					value={value}
					autoComplete="off"
					helperText={helperText}
					type={type}
					id={value}
					sx={{ width: "100%" }}
					error={error}
					onChange={(e) => handleChange(index, e, valueToChange)}></InputBase>
			</FormControl>
		</Box>
	);
};
export default BasicInput;
