import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import UnstyledLink from "../links/unstyled-link/UnstyledLink";
import StyledLink from "../links/styled-link/StyledLink";
import UnStyledButton from "../buttons/UnStyledButtonComponent";
import tastetIcon from "../../assets/TastetWhite.svg";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import { useState } from "react";
import i18next from "i18next";
import { useTheme } from "@mui/material";
import { useAuth } from "../../contexts/authContext/authContext";
import UserProfile from "../modals/profile";
import { useTranslation } from "react-i18next";
const Header = ({ }) => {
	const {
		user,
		handleLogOut,
		userProfile,
		setUserProfile,
		submitProfileChange,
		openUserProfile,
		setOpenUserProfile,
		error,
		setInputError,
		inputError
	} = useAuth();
	const theme = useTheme();
	const { t, i18n } = useTranslation();

	const handleLanguage = () => {
		let language;
		i18next.language === "fr" ? (language = "en") : (language = "fr");
		i18next.changeLanguage(language, (err, t) => {
			if (err) return console.log("something went wrong loading", err);
			t("key"); // -> same as i18next.t
		});
	};


	const handleOpen = () => setOpenUserProfile(true);
	const handleClose = () => setOpenUserProfile(false);
	return (
		<Box>
			<AppBar
				position="fixed"
				sx={{ backgroundColor: theme.palette.color_green, minHeight: "80px" }}>
				<Toolbar
					sx={{
						justifyContent: {
							xs: "space-between",
							md: "space-between",
						},
						minHeight: "80px",
					}}>
					<UnstyledLink href={!user ? "/" : "/merchant-dashboard/home"}>
						<img src={tastetIcon} alt="tastet icon"></img>
					</UnstyledLink>
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							alignItems: "flex-end",
						}}>
						<UnStyledButton
							onClick={handleLanguage}
							color="inherit"
							customStyle={{ color: theme.palette.color_white }}>
							{i18next.language === "fr" ? "EN" : "FR"}
						</UnStyledButton>

						<Box sx={{ display: "flex", alignItems: "flex-start" }}>
							{user === null ? (
								<UnstyledLink
									href={"/login"}
									underline="none"
									customStyle={{
										borderWidth: "1px",
										borderStyle: "solid",
										borderColor: "white",
										borderRadius: "6px",
										backgroundColor: "inherit",
										m: "0 5px 10px 0",
										padding: "6px 8px",
									}}
									customLinkStyle={{ color: "white" }}>
									{t("BUTTONS.LOGIN")}
								</UnstyledLink>
							) : (
								<>
									<AccountCircleOutlinedIcon sx={{ fontSize: "2.5rem", cursor: "pointer", mr: '15px' }} onClick={() => handleOpen()} />
									<UnStyledButton
										onClick={handleLogOut}
										customStyle={{
											color: "white",
											borderWidth: "1px",
											borderStyle: "solid",
											borderColor: "white",
											borderRadius: "6px",
											backgroundColor: "inherit",
											m: "0 5px 10px 0",
											padding: "6px 8px",
										}}>
										{t("BUTTONS.LOGOUT")}
									</UnStyledButton>
								</>
							)}
						</Box>
					</Box>
				</Toolbar>
			</AppBar>

			<UserProfile
				open={openUserProfile}
				onClose={handleClose}
				userProfile={userProfile}
				setUserProfile={setUserProfile}
				submitProfileChange={submitProfileChange}
				error={error}
				setInputError={setInputError}
				inputError={inputError}
			/>
		</Box>
	);
};
export default Header;

/**
 * 
 * 
 * 
 * 	<StyledLink
								href={"/address-claim/1"}
								underline="none"
								customLinkStyle={{ boxShadow: "none" }}>
								GET STARTED
							</StyledLink>
 * 
 * 
 * 
 */
