import Box from "@mui/material/Box";
import { useTheme } from "@mui/material";

const StyledDashBoardSectionTitleContainer = ({ children }) => {
	const theme = useTheme();
	const defaultSectionTitleStyle = {
		backgroundColor: theme.palette.color_yellow,
		width: "100%",
		borderRadius: "5px",
		padding: "5px",
	};

	return <Box sx={{ ...defaultSectionTitleStyle }}>{children}</Box>;
};
export default StyledDashBoardSectionTitleContainer;
