import BasicPageLayout from "../../components/sections/basic-page-layout/BasicPageLayout";
import TextField from "../../components/text-fields/TextField";
import BasicInput from "../../components/inputs/basic-input/BasicInput";
import LoginInput from "../../components/inputs/login-input/LoginInput";
import StyledButton from "../../components/buttons/StyledButtonComponent";
import {
	MERCHANT_DASHBOARD_HOME_TAB,
	ADMIN_DASHBOARD_SEARCH_TAB,
} from "../../config/routes";

import { useAuth } from "../../contexts/authContext/authContext";
import AlertModal from "../../components/modals/alert";
import tastetIcon from "../../assets/Tastet.svg";
import { Box } from "@mui/system";
import { FormControl } from '@mui/material';
import { useTranslation } from "react-i18next";

const LoginPage = ({ }) => {
	const { setEmail, handleLogin, email, open, error, setOpen, setError, openAlertModal, setOpenAlertModal } =
		useAuth();
	const { t, i18n } = useTranslation();

	return (
		<BasicPageLayout
			customLayout={{
				display: "flex",
				flexDirection: { xs: "column", md: "column" },
				backgroundColor: "#f6f2eb",
				alignItems: "center",
				mt: "41px",
				minHeight: "calc(100vh - 41px)",
			}}>
			<img
				src={tastetIcon}
				width="300px"
				alt="Tastet Logo"
				style={{ marginTop: "125px", marginBottom: "100px" }}></img>
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					padding: '15px',
					maxWidth: { xs: "100%", md: "700px", lg: "700px" },
				}}>
				<TextField
					variant={"p"}
					sx={{ fontWeight: "900", fontSize: "2rem", textAlign: "center" }}>
					{t("LOGIN_PAGE.FIRST_TITLE")}
				</TextField>
				<TextField
					variant={"p"}
					sx={{
						fontSize: "1.25rem",
						textAlign: "center",
						whiteSpace: { xs: "revert", lg: "nowrap" },
					}}>
					{t("LOGIN_PAGE.FIRST_PARAGRAPH")}
				</TextField>
				<TextField
					variant={"p"}
					sx={{
						fontSize: "1.25rem",
						maxWidth: "inherit",
						textAlign: "center",
						paddingTop: "20px",
					}}>
					{t("LOGIN_PAGE.SECOND_PARAGRAPH")}
					<a href={`mailto:sarah@tastet.ca?subject=${t('EMAIL.ADD_MY_EMAIL_SUBJECT')}`}>
						{t("BUTTONS.CONTACT_US")}
					</a>{" "}
					{t("LOGIN_PAGE.THIRD_PARAGRAPH")}
				</TextField>
			</Box>
			<FormControl sx={{
				width: { xs: "100%", lg: "700px" },
				display: "flex",
				flexDirection: "column",
				justifyContent: "center",
				alignItems: "center",
				padding: '15px'
			}}>
				<LoginInput
					type="email"
					required={true}
					label={t("LABELS.EMAIL")}
					handleChange={setEmail}></LoginInput>

				<StyledButton
					sx={{
						color: "black",
						backgroundColor: "#edff22",
						mt: "50px",
						borderRadius: "6px",
						width: { xs: "100%", sm: '50%', md: "500px", lg: "500px" },
					}}
					fullWidth={true}
					type="submit"
					handleClick={(e) => handleLogin(e, email)}>
					{t("BUTTONS.LOGIN")}
				</StyledButton>
			</FormControl>
			{openAlertModal && (
				<AlertModal
					open={openAlertModal}
					setOpen={setOpenAlertModal}
					email={email}
					error={error}
					setError={setError}
				/>
			)}
		</BasicPageLayout>
	);
};
export default LoginPage;

/**
 * <TextField variant={"p"}>
				<TextField
					variant={"p"}
					sx={{
						fontWeight: "900",

						mr: "5px",
					}}>
					or
				</TextField>
				<TextField variant={"p"}>
					<a
						href="/address-claim/1"
						style={{ color: "black", hover: "cursor:pointer" }}>
						claim your address
					</a>
				</TextField>
			</TextField>
 * 
 * 
 * 
 * 
 * 
 * 
 */
