import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material";

const StyledExternalLink = ({
	href,
	target,
	customBoxStyle,
	customLinkStyle,
	children,
	state,
}) => {
	const theme = useTheme();
	const navigate = useNavigate();
	const defaultStyledLinkStyle = {
		display: "flex",
		justifyContent: "center",
		color: "black",
		backgroundColor: theme.palette.color_yellow,
		borderRadius: "6px",
		padding: "6px 8px",
		m: { xs: "5px 5px 5px 5px", md: "0 0 10px 0" },
		boxShadow: "2px 3px #888888",
		width: { xs: "100%", md: "100%", lg: "300px" },
		cursor: "pointer",
	};

	console.log('href', href)

	return (
		<Link
			sx={{ ...defaultStyledLinkStyle, ...customLinkStyle }}
			href={href}
			target="_blank"
			underline="none">
			{children}
		</Link>
	);
};
export default StyledExternalLink;
