import TextField from "../../../components/text-fields/TextField";
import StyledLink from "../../../components/links/styled-link/StyledLink";
import StyledInput from "../../../components/inputs/styled-input/StyledInput";

import DashboardSection from "../../../components/sections/dashboard-section/DashboardSection";
import DropDownSearchInput from "../../../components/inputs/dropdown/DropDownSearchInput";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import StyledButton from "../../../components/buttons/StyledButtonComponent";
import AccordionComponent from "../../../components/accordion/AccordionComponent";
import DropDownMenu from "../../../components/drop-down-menu/DropDownMenuComponent";
import Box from "@mui/material/Box";
import SortIcon from "@mui/icons-material/Sort";
import Divider from "@mui/material/Divider";
import ListComponent from "../../../components/list/ListComponent";
import ListItemComponent from "../../../components/list-item/ListItemComponent";
import FilterAccordion from "../../../components/accordion/FilterAccordionComponent";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { useTheme } from "@mui/material";
const AdminListTab = ({}) => {
	const theme = useTheme();
	const categoryFilters = [
		{ category: "all" },
		{ category: "restaurant" },
		{ category: "cafe" },
		{ category: "bar" },
		{ category: "bakery" },
		{ category: "high-end" },
		{ category: "byob" },
		{ category: "wine-bar" },
	];

	const categoryFiltersWrapper = {
		display: "flex",
		flexWrap: "wrap",
	};

	const regionFilters = [
		{ category: "Old-Port" },
		{ category: "Outremont" },
		{ category: "Plateau" },
	];

	const cuisineFilters = [
		{ category: "Old-Port" },
		{ category: "Outremont" },
		{ category: "Plateau" },
	];

	const statusFilters = [
		{ category: "Old-Port" },
		{ category: "Outremont" },
		{ category: "Plateau" },
	];

	const filterByWrapper = {
		mt: "20px",
		position: "relative",
		display: "flex",
		alignItems: "center",
		flexWrap: "wrap",
		borderRadius: "5px",
		backgroundColor: theme.palette.color_beige,
	};

	const dummyData = [
		{
			displayName: "Weekend chillax",
			city: "Montreal, quebec",
			address:
				"longue addresse pour tester un nom super long, une autre addresse",
		},
		{
			displayName: "Où il fait bon boire",
			city: "Montreal, critère",
			address:
				"longue addresse pour tester un nom super long, une autre addresse",
		},
		{
			displayName: "Une sortie entre copine",
			city: "Montreal, plus de critère",
			address:
				"longue addresse pour tester un nom super long, une autre addresse",
		},
	];

	return (
		<>
			<TextField variant={"h1"} sx={{ fontWeight: "900", fontSize: "2rem" }}>
				Tastet Lists
			</TextField>

			<StyledLink href="/admin-dashboard/list/create">+ CREATE LIST</StyledLink>

			<StyledInput label={"SEARCH"}></StyledInput>
			<Box sx={{ ...filterByWrapper }}>
				<FilterAccordion>
					<AccordionSummary
						aria-controls="panel1a-content"
						id="panel1a-header"
						expandIcon={<ExpandMoreIcon />}>
						<FilterAltOutlinedIcon />
						<TextField>Filter by:</TextField>
						<TextField>REGION CUISINE STATUS</TextField>
					</AccordionSummary>
					<AccordionDetails>
						<Box>
							<TextField>REGION</TextField>
							{regionFilters.map((subfilter) => (
								<FormControlLabel
									control={<Checkbox defaultChecked />}
									label={subfilter.category}
								/>
							))}
						</Box>
						<Box>
							<TextField>CUISINE</TextField>
							{cuisineFilters.map((subfilter) => (
								<FormControlLabel
									control={<Checkbox defaultChecked />}
									label={subfilter.category}
								/>
							))}
						</Box>
						<Box>
							<TextField>STATUS</TextField>
							{statusFilters.map((subfilter) => (
								<FormControlLabel
									control={<Checkbox defaultChecked />}
									label={subfilter.category}
								/>
							))}
						</Box>
						<TextField>
							Lorem ipsum dolor sit amet, consectetur adipiscing elit.
							Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
							eget.
						</TextField>
					</AccordionDetails>
				</FilterAccordion>

				<FilterAccordion customStyle={{}}>
					<AccordionSummary
						aria-controls="panel1a-content"
						id="panel1a-header"
						expandIcon={<ExpandMoreIcon />}>
						<SortIcon />
						<TextField
							variant={"h6"}
							sx={{ fontWeight: "900", fontSize: "1.25rem" }}>
							Sort By:
						</TextField>
					</AccordionSummary>
					<AccordionDetails>
						<TextField>
							Lorem ipsum dolor sit amet, consectetur adipiscing elit.
							Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
							eget.
						</TextField>
					</AccordionDetails>
				</FilterAccordion>
			</Box>

			<ListComponent
				tableHeaders={[
					"Title",
					"Criterias",
					"Address",
					"View",
					"Edit",
					"Delete",
				]}
				dummyData={dummyData}
				isVisible={true}
				darkMode={true}
				isAdmin={true}
				isList={true}></ListComponent>
		</>
	);
};
export default AdminListTab;
