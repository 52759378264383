import { useParams, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import TextField from "../../../components/text-fields/TextField";
import DashboardSection from "../../../components/sections/dashboard-section/DashboardSection";
import PhotoCard from "../../../components/cards/PhotoCardComponent";
import defaultImage from "../../../../src/Default-Image.png";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import CheckBox from "@mui/icons-material/CheckBox";
import StyledMailto from "../../../components/links/styled-mailto/StyledMailto";
import { MERCHANT_DASHBOARD_RESTAURANT_DETAILS } from "../../../config/routes";
import BreadCrumbs from "../../../components/breadcrumbs/BreadCrumbs";

const MerchantImagePurchasePage = ({ }) => {
	const location = useLocation();
	const params = useParams();
	const [pictures, setPictures] = useState();
	const [selectedPictures, setSelectedPictures] = useState([])

	useEffect(() => {
		setPictures(location?.state);
	}, []);

	const filterPictureName = (selectedPicture) => {
		return selectedPicture.replace('https://tastet.ca/', "");
	}


	const handleSelectedPicture = (selectedPicture, index, e) => {
		let filteredPictureName = filterPictureName(selectedPicture);
		console.log('filteredPictureName', filteredPictureName)
		if (e.target.checked !== false) {
			setSelectedPictures((prevState) => ([...prevState, filteredPictureName]))
		} else if (e.target.checked === false) {
			let filteredArray = selectedPictures.filter(item => item !== filteredPictureName)
			console.log('filteredArray', filteredArray)
			setSelectedPictures(filteredArray)
		}
	}

	const handleSelectAll = (e) => {
		if (e.target.checked !== false) {
			setSelectedPictures(pictures.map(picture => picture.url))
		}
		else {
			setSelectedPictures([])
		}
	}
	const customStyledMailto = {
		position: 'sticky',
		height: '10px',
		top: '200px',
		minWidth: "fit-content"
	};

	const subject = "Picture purchase"

	console.log('.media?.gallery')


	return (
		<>
			<BreadCrumbs href={`${MERCHANT_DASHBOARD_RESTAURANT_DETAILS}${params.publicId}`} state={location.state} customStyle={{ marginTop: "-30px" }}>Back to {location.state.name} view</BreadCrumbs>
			<TextField variant={"h1"}>Purchase Photos</TextField>
			<TextField variant={"p"}>
				Select the photos you wish to purchase and then send us the order. We
				will send you the photos and the invoice through your email account.
			</TextField>
			{/* <Checkbox onClick={(e) => handleSelectAll(e)}></Checkbox> */}
			<DashboardSection customStyle={{ display: "flex", justifyContent: "space-around", position: "relative" }}>
				<Box sx={{ display: "flex", flexWrap: "wrap", justifyContent: "space-around", alignItems: "center" }}
				>
					{location.state.media?.gallery?.map((data, index) => (

						<PhotoCard data={data} index={index} handleSelectedPicture={handleSelectedPicture} />

					))}
				</Box>
				<StyledMailto customStyle={customStyledMailto} href={`mailto:sarah@tastet.ca?subject=${subject}&body=${selectedPictures}`}>MAIL US</StyledMailto>
			</DashboardSection>
		</>
	);
};
export default MerchantImagePurchasePage;
