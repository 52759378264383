import { useState } from "react";

import dayjs from "dayjs";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";

const MuiDatePicker = ({ value, handleChange, valueToChange, label }) => {

	return (
		<LocalizationProvider dateAdapter={AdapterDayjs}>
			<MobileDatePicker
				label={label}
				sx={{ width: { xs: "100%", lg: "275px" }, color: "black" }}
				value={value ? dayjs(value) : null}
				inputFormat="YYYY/MM/D"
				onChange={(newValue) => {
					handleChange(
						dayjs(newValue).format("YYYY/MM/D").toString(),
						valueToChange
					);
				}}
				renderInput={(params) => (
					<TextField sx={{ width: { xs: "100%", lg: "275px" }, color: "black" }} {...params} />
				)}
			/>
		</LocalizationProvider>
	);
};

export default MuiDatePicker;
